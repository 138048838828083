import { filterDateTypeConfig } from '../../graphql/enums/filterDateType'


export const newEventsPrefix = 'eventsResults'


export const newEventsSortConfig = [
  {
    name: 'date',
    slug: 'par-date',
    field: 'begin_at',
    order: 'ASC',
  },
  {
    name: 'location',
    slug: 'par-lieu',
    field: 'place',
    order: 'ASC',
  },
]

export const newEventsFiltersConfig = [
  {
    type: 'checkbox',
    isPath: true,
    name: 'date',
    slug: 'date',
    prefix: 'filterDateType',
    options: filterDateTypeConfig,
  },
]
