import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

function LegacyBrowser(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()

  const [isHidden, setIsHidden] = useState(false)

  const handleIgnore = useCallback(() => {
    setIsHidden(!isHidden)
  }, [isHidden])

  if (isHidden) {
    return null
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.text}>
          <div className={classes.title}>{t('legacyBrowser_title')}</div>
          <div className={classes.subtitle}>{t('legacyBrowser_subtitle')}</div>
        </div>
        <div className={classes.button}>
          <ActionButton
            link={t('legacyBrowser_cta_link_update')}
            label={t('legacyBrowser_cta_label_update')}
            className={classes.updateButton}
          />
          <ActionButton
            label={t('legacyBrowser_cta_label_ignore')}
            isOutlined
            onClick={handleIgnore}
          />
        </div>

      </div>
    </div>
  )
}

LegacyBrowser.styles = styles

LegacyBrowser.propTypes = {
}

LegacyBrowser.defaultProps = {
}

export default withMemo()(LegacyBrowser)
