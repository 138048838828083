import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import { selectors as AuthSelectors } from '../../redux/auth/redux'
import AuthSagas from '../../redux/auth/sagas'


export default class UserRegisterWelcomeSagas {

  // eslint-disable-next-line complexity
  static* init(ctx) {
    yield put(showLoading())

    const me = yield select(AuthSelectors.user)

    if (!me) {
      yield call(AuthSagas.redirectToLogin, ctx.res)
      return {}
    }

    yield all([
      yield put(hideLoading()),
    ])

    return {}
  }



  static* loop() {
    yield all([

    ])
  }

}
