import { createAction, createReducer } from 'redux-act'


export const key = 'EmailUserNotifications'


//
// Initial state
//
const initialState = {}

//
// Actions
//
export const actions = {
  init: createAction('EmailUserNotifications: init'),
  redirect: createAction('EmailUserNotifications: redirect'),
}

//
// Reducer
//
export const reducer = createReducer({}, initialState)

//
// Selectors
//
export const selectors = {}
