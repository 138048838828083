import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import withMemo from '../../decorators/WithMemo'

import NotFound from './index'


function NotFoundWrapper() {
  const isConnected = useSelector(AuthSelectors.isConnected)
  const { t } = useTranslation()
  const pageSelector = useSelector(AppSelectors.page)
  const loginLink = pageSelector(pageType.LOGIN)?.link
  const homeLink = pageSelector(pageType.HOME)?.link
  const offersLink = pageSelector(pageType.OFFERS)?.link
  const directoryLink = pageSelector(pageType.DIRECTORY)?.link
  const userAccountLink = Router.getRouteUrl(routes.userDashboard)

  const data = {
    image: '/images/404.png',
    title: t('error_404_title'),
    subtitle: t('error_404_subTitle'),
    text: t('error_404_text'),
    usefulLinks: {
      title: t('error_404_linksTitle'),
      links: [
        {
          id: 'home',
          label: t('error_404_linkHome'),
          link: homeLink,
        },
        isConnected ? {
          id: 'account',
          label: t('error_404_linkAccount'),
          link: userAccountLink,
        } : {
          id: 'login',
          label: t('error_404_linkLogin'),
          link: loginLink,
        },
        {
          id: 'offers',
          label: t('error_404_linkOffers'),
          link: offersLink,
          route: routes.page.name,
          routeParams: { slug: offersLink },
        },
        {
          id: 'companies',
          label: t('error_404_linkDirectory'),
          link: directoryLink,
          route: routes.page.name,
          routeParams: { slug: directoryLink },
        },
      ],
    },
  }

  return <NotFound {...data} />
}

NotFoundWrapper.propTypes = {}

NotFoundWrapper.defaultProps = {}

export default withMemo()(NotFoundWrapper)
