/* eslint-disable max-lines */
const directoryType = {
  COMPANY: 'COMPANY',
  PROFILE: 'PROFILE',
}

const directoryTypeArray = [
  directoryType.COMPANY,
  directoryType.PROFILE,
]

const filterCompanyType = {
  FREEMIUM: 'FREEMIUM',
  PREMIUM: 'PREMIUM',
  AMBASSADOR: 'AMBASSADOR',
}

const filterCompanyTypeArray = [
  filterCompanyType.FREEMIUM,
  filterCompanyType.PREMIUM,
  filterCompanyType.AMBASSADOR,
]

const filterCompanyTypeSlugs = {
  [filterCompanyType.FREEMIUM]: 'standard',
  [filterCompanyType.PREMIUM]: 'premium',
  [filterCompanyType.AMBASSADOR]: 'ambassador',
}

const offerType = {
  OFFER_TYPE_PURCHASING_CONSULTATION: 'OFFER_TYPE_PURCHASING_CONSULTATION',
  OFFER_TYPE_CALL_FOR_TENDER: 'OFFER_TYPE_CALL_FOR_TENDER',
  OFFER_TYPE_CO_CONTRACTING: 'OFFER_TYPE_CO_CONTRACTING',
  OFFER_TYPE_CIRCULAR_ECONOMY: 'OFFER_TYPE_CIRCULAR_ECONOMY',
  OFFER_TYPE_SOURCING: 'OFFER_TYPE_SOURCING',
  OFFER_TYPE_OGCN: 'OFFER_TYPE_OGCN',
}

const offerTypeFilterArray = [
  offerType.OFFER_TYPE_PURCHASING_CONSULTATION,
  offerType.OFFER_TYPE_CALL_FOR_TENDER,
  offerType.OFFER_TYPE_CO_CONTRACTING,
  offerType.OFFER_TYPE_CIRCULAR_ECONOMY,
  offerType.OFFER_TYPE_SOURCING,
  offerType.OFFER_TYPE_OGCN,
]

const offerTypeSlugs = {
  [offerType.OFFER_TYPE_PURCHASING_CONSULTATION]: 'pc',
  [offerType.OFFER_TYPE_CALL_FOR_TENDER]: 'cft',
  [offerType.OFFER_TYPE_CO_CONTRACTING]: 'cc',
  [offerType.OFFER_TYPE_CIRCULAR_ECONOMY]: 'ce',
  [offerType.OFFER_TYPE_SOURCING]: 'sour',
  [offerType.OFFER_TYPE_OGCN]: 'ogcn',
}

const offerSource = {
  ALL: 'ALL',
  CCI: 'CCI',
}

const offerSourceArray = [
  offerSource.ALL,
  offerSource.CCI,
]

const offerBudget = {
  OFFER_BUDGET_LESS_THAN_5K: 'OFFER_BUDGET_LESS_THAN_5K',
  OFFER_BUDGET_BETWEEN_5K_AND_10K: 'OFFER_BUDGET_BETWEEN_5K_AND_10K',
  OFFER_BUDGET_BETWEEN_10K_AND_20K: 'OFFER_BUDGET_BETWEEN_10K_AND_20K',
  OFFER_BUDGET_BETWEEN_20K_AND_50K: 'OFFER_BUDGET_BETWEEN_20K_AND_50K',
  OFFER_BUDGET_BETWEEN_50K_AND_100K: 'OFFER_BUDGET_BETWEEN_50K_AND_100K',
  OFFER_BUDGET_MORE_THAN_100K: 'OFFER_BUDGET_MORE_THAN_100K',
}

const offerBudgetArray = [
  offerBudget.OFFER_BUDGET_LESS_THAN_5K,
  offerBudget.OFFER_BUDGET_BETWEEN_5K_AND_10K,
  offerBudget.OFFER_BUDGET_BETWEEN_10K_AND_20K,
  offerBudget.OFFER_BUDGET_BETWEEN_20K_AND_50K,
  offerBudget.OFFER_BUDGET_BETWEEN_50K_AND_100K,
  offerBudget.OFFER_BUDGET_MORE_THAN_100K,
]

const offerBudgetSlugs = {
  [offerBudget.OFFER_BUDGET_LESS_THAN_5K]: '5k',
  [offerBudget.OFFER_BUDGET_BETWEEN_5K_AND_10K]: '10k',
  [offerBudget.OFFER_BUDGET_BETWEEN_10K_AND_20K]: '20k',
  [offerBudget.OFFER_BUDGET_BETWEEN_20K_AND_50K]: '50k',
  [offerBudget.OFFER_BUDGET_BETWEEN_50K_AND_100K]: '100k',
  [offerBudget.OFFER_BUDGET_MORE_THAN_100K]: '100k+',
}

const offerDeadline = {
  OFFER_DEADLINE_1_MONTH: 'OFFER_DEADLINE_1_MONTH',
  OFFER_DEADLINE_3_MONTHS: 'OFFER_DEADLINE_3_MONTHS',
  OFFER_DEADLINE_6_MONTHS: 'OFFER_DEADLINE_6_MONTHS',
  OFFER_DEADLINE_THIS_YEAR: 'OFFER_DEADLINE_THIS_YEAR',
}

const offerDeadlineArray = [
  offerDeadline.OFFER_DEADLINE_1_MONTH,
  offerDeadline.OFFER_DEADLINE_3_MONTHS,
  offerDeadline.OFFER_DEADLINE_6_MONTHS,
  offerDeadline.OFFER_DEADLINE_THIS_YEAR,
]

const offerDeadlineSlugs = {
  [offerDeadline.OFFER_DEADLINE_1_MONTH]: '1m',
  [offerDeadline.OFFER_DEADLINE_3_MONTHS]: '3m',
  [offerDeadline.OFFER_DEADLINE_6_MONTHS]: '6m',
  [offerDeadline.OFFER_DEADLINE_THIS_YEAR]: '1y',
}

const postType = {
  POST_TYPE_LINKING: 'POST_TYPE_LINKING',
  POST_TYPE_PURCHASING_GROUP: 'POST_TYPE_PURCHASING_GROUP',
  POST_TYPE_CALL_FOR_PROMOTION: 'POST_TYPE_CALL_FOR_PROMOTION',
}

const postTypeArray = [
  postType.POST_TYPE_LINKING,
  postType.POST_TYPE_PURCHASING_GROUP,
  postType.POST_TYPE_CALL_FOR_PROMOTION,
]

const postTypeSlugs = {
  [postType.POST_TYPE_LINKING]: 'linking',
  [postType.POST_TYPE_PURCHASING_GROUP]: 'purchasing-group',
  [postType.POST_TYPE_CALL_FOR_PROMOTION]: 'call-for-promotion',
}

const eventType = {
  EVENT_EXHIBITION: 'EVENT_EXHIBITION',
  EVENT_CONFERENCE: 'EVENT_CONFERENCE',
  EVENT_NETWORKING: 'EVENT_NETWORKING',
  EVENT_BUSINESS_CONVENTION: 'EVENT_BUSINESS_CONVENTION',
  EVENT_BUSINESS_PLACE: 'EVENT_BUSINESS_PLACE',
  EVENT_FORMATION: 'EVENT_FORMATION',
}

const eventTypeArray = [
  eventType.EVENT_EXHIBITION,
  eventType.EVENT_CONFERENCE,
  eventType.EVENT_NETWORKING,
  eventType.EVENT_BUSINESS_CONVENTION,
  eventType.EVENT_BUSINESS_PLACE,
  eventType.EVENT_FORMATION,
]

const eventTypeSlugs = {
  [eventType.EVENT_EXHIBITION]: 'exhibition',
  [eventType.EVENT_CONFERENCE]: 'conference',
  [eventType.EVENT_NETWORKING]: 'networking',
  [eventType.EVENT_BUSINESS_CONVENTION]: 'business-convention',
  [eventType.EVENT_BUSINESS_PLACE]: 'business-place',
  [eventType.EVENT_FORMATION]: 'formation',
}

const filterDateType = {
  FILTER_DATE_SAME_DAY: 'FILTER_DATE_SAME_DAY',
  FILTER_DATE_SAME_WEEK: 'FILTER_DATE_SAME_WEEK',
  FILTER_DATE_SAME_MONTH: 'FILTER_DATE_SAME_MONTH',
}

const filterDateTypeArray = [
  filterDateType.FILTER_DATE_SAME_DAY,
  filterDateType.FILTER_DATE_SAME_WEEK,
  filterDateType.FILTER_DATE_SAME_MONTH,
]

const filterDateTypeSlugs = {
  [filterDateType.FILTER_DATE_SAME_DAY]: 'today',
  [filterDateType.FILTER_DATE_SAME_WEEK]: 'current-week',
  [filterDateType.FILTER_DATE_SAME_MONTH]: 'current-month',
}

const filterDateTypeReverseSlugs = {
  today: filterDateType.FILTER_DATE_SAME_DAY,
  'current-week': filterDateType.FILTER_DATE_SAME_WEEK,
  'current-month': filterDateType.FILTER_DATE_SAME_MONTH,
}

const directoryTypeOptions = directoryTypeArray.map((value) => ({
  value,
  id: value,
}))

const filterCompanyTypeConfig = filterCompanyTypeArray.map((value) => ({
  id: value,
  value: filterCompanyTypeSlugs[value],
}))

const offerTypeConfig = offerTypeFilterArray.map((value) => ({
  id: value,
  value: offerTypeSlugs[value],
}))

const offerSourceOptions = offerSourceArray.map((value) => ({
  value,
  id: value,
}))

const offerBudgetConfig = offerBudgetArray.map((value) => ({
  id: value,
  value: offerBudgetSlugs[value],
}))

const offerDeadlineConfig = offerDeadlineArray.map((value) => ({
  id: value,
  value: offerDeadlineSlugs[value],
}))

const postTypeConfig = postTypeArray.map((value) => ({
  id: value,
  value: postTypeSlugs[value],
}))

const eventTypeConfig = eventTypeArray.map((value) => ({
  id: value,
  value: eventTypeSlugs[value],
}))

const filterDateTypeConfig = filterDateTypeArray.map((value) => ({
  id: value,
  value: filterDateTypeSlugs[value],
}))

module.exports.offerTypeConfig = offerTypeConfig
module.exports.offerBudgetConfig = offerBudgetConfig
module.exports.offerSourceOptions = offerSourceOptions
module.exports.offerDeadlineConfig = offerDeadlineConfig

module.exports.postTypeConfig = postTypeConfig

module.exports.directoryTypeOptions = directoryTypeOptions
module.exports.filterCompanyTypeConfig = filterCompanyTypeConfig

module.exports.eventTypeConfig = eventTypeConfig
module.exports.filterDateTypeConfig = filterDateTypeConfig
module.exports.filterDateTypeReverseSlugs = filterDateTypeReverseSlugs

module.exports.companyFiltersConfig = (certifications, sectors, domains) => [
  {
    visible: false,
    isPath: true,
    name: 'type',
    slug: 'type',
    prefix: 'filterType',
    options: directoryTypeOptions,
  },
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'types',
    slug: 'company-type',
    prefix: 'filterCompanyType',
    options: filterCompanyTypeArray.map((value) => ({
      id: value,
      value: filterCompanyTypeSlugs[value],
    })),
  },
  {
    type: 'radio',
    isMulti: false,
    isPath: true,
    name: 'sector',
    slug: 'sector',
    prefix: 'filterSector',
    options: sectors && sectors.map((certif) => ({
      value: certif.id,
      name: certif.id,
      id: certif.id,
    })),
  },
  ...domains ? (
    [{
      type: 'checkbox',
      isMulti: true,
      isPath: true,
      name: 'domains',
      slug: 'domains',
      prefix: 'filterDomains',
      options: domains.map((d) => ({
        value: d.id,
        name: d.id,
        id: d.id,
      })),
    }]) : [],
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'certifications',
    slug: 'certifications',
    prefix: 'filterCertificationsType',
    options: certifications && certifications.map((certif) => ({
      value: certif.id,
      name: certif.id,
      id: certif.id,
    })),
  },
]

module.exports.profileFiltersConfig = (companyFunctions) => [
  {
    visible: false,
    isPath: true,
    name: 'type',
    slug: 'type',
    prefix: 'filterType',
    options: directoryTypeOptions,
  },
  {
    type: 'checkbox',
    isMulti: true,
    isPath: true,
    name: 'company_functions',
    slug: 'role',
    prefix: 'filterProfileType',
    options: companyFunctions && companyFunctions.map((func) => ({
      label: func.name,
      value: func.id,
      name: func.id,
      id: func.id,
    })),
  },
]
