import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserCompany'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  updateCompany: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userCompany: setData', (data) => ({ data })),
  setLoaded: createAction('userCompany: setLoaded', (loaded) => ({ loaded })),
  //
  updateCompany: createAction('userCompany: updateCompany', (values) => ({ values })),
  updateCompanySuccess: createAction('userCompany: updateCompanySuccess', (data) => data),
  updateCompanyError: createAction('userCompany: updateCompanyError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'updateCompany'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const updateCompany = (state) => root(state).updateCompany

export const selectors = {
  isLoaded,
  data,
  updateCompany,
}
