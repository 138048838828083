import gql from 'graphql-tag'


export const MenuableItemFragment = {
  fragment: gql`
      fragment menuableItem on Menu {
          menuable {
              __typename
              ... on Page {
                  title
                  slug
                  full_slug
              }
              ... on Route {
                  name
                  slug
                  key
              }
          }
      }
  `,
}
