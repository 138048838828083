import { hideLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import auth from '../../graphql/query/Auth'
import ApiSagas from '../../redux/api/sagas'
import AuthSagas from '../../redux/auth/sagas'
import { actions as AuthActions, selectors as authSelectors } from '../../redux/auth/redux'

import { actions } from './redux'


export default class EmailVerificationSagas {

  static* init({ payload }) {
    const { query } = payload

    if (query.email) {
      const email = query?.email
      const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!emailFormat.test(email)) {
        yield put(hideLoading())
        yield call(AuthSagas.redirectToLogin)
        return
      }

      const input = {
        email: query?.email,
        hash: query?.hash || query['amp;hash'],
        id: query?.id || query['amp;id'],
        signature: query?.signature || query['amp;signature'],
        discount: query?.discount || query['amp;discount'] || null,
      }

      if (!input.hash || !input.id || !input.signature) {
        yield put(hideLoading())
        yield call(AuthSagas.redirectToLogin)
        return
      }

      const result = yield call(ApiSagas.query, auth.verifyEmail, { input })

      if (!result.errors) {
        const data = result.data.verifyEmail

        yield put(AuthActions.setToken(data))
        yield call(AuthSagas.tokenProcess, data)
        yield call(EmailVerificationSagas.redirect, query)
        return
      }
    }

    yield put(hideLoading())
    yield call(AuthSagas.redirectToLogin)
  }

  static* redirect(query) {
    const user = yield select(authSelectors.user)

    if (user?.company?.is_premium && !user?.company?.isActivePremium) {
      if (query?.discount || query['amp;discount']) {
        yield call(AuthSagas.redirectToRegisterPremiumWithDiscount, query?.discount || query['amp;discount'])
      } else {
        yield call(AuthSagas.redirectToRegisterPremium)
      }
    } else if (user?.company?.is_premium && user?.company?.isActivePremium) {
      yield call(AuthSagas.redirectToDashboard)
    } else {
      yield call(AuthSagas.redirectToRegisterWelcome)
    }
  }

  static* loop() {
    yield all([
      takeLatest(actions.redirect.getType(), EmailVerificationSagas.redirect),
      takeLatest(actions.init.getType(), EmailVerificationSagas.init),
    ])
  }

}
