import { all, call, select, takeLatest } from 'redux-saga/effects'

import { addLeadingSlash, redirect, routeParamSlug } from '../../helpers/UrlHelpers'
import Notification from '../../data/models/Notification'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'
import ApiSagas from '../../redux/api/sagas'
import userQueries from '../../graphql/query/User'

import { actions } from './redux'


export default class EmailUserNotificationsSaga {

  static* init({ payload }) {
    const { query } = payload

    const pages = yield select(AppSelectors.pages)

    if (query.type) {
      const possibleParams = {}

      let params = {}

      if (query.payload) {
        try {
          const parsed = JSON.parse(query.payload)

          if (typeof parsed === 'object' && !Array.isArray(parsed)) {
            params = parsed
          }
        } catch (e) {
          /* noop */
        }
      }

      if (query.offer_id) {
        possibleParams.id = query.offer_id
      }

      if (query.type === 'new_company' && !!params?.slug) {
        yield Router.replaceRoute(routes.company.name, { slug: routeParamSlug(params.slug) })
        return
      }
      if (query.type === 'contact_candidate' && !!params?.id) {
        yield Router.replaceRoute(routes.userOffer.name, { id: routeParamSlug(params.id) })
        return
      }
      if (query.type === 'updated_offers' && !!params?.slug) {
        yield Router.replaceRoute(routes.offer.name, { slug: routeParamSlug(params.slug) })
        return
      }

      const id = Notification.notifsInfosTypes[query.type] || Notification.notifsAlertsTypes[query.type]

      if (id) {
        if (Object.prototype.hasOwnProperty.call(pageType, id)) {
          const link = pages[pageType[id]]?.link

          if (query.id) {
            yield call(ApiSagas.query, userQueries.markNotificationAsRead, { id: query.id })
          }

          if (link) {
            yield call(redirect, null, addLeadingSlash(link))
            return
          }
        }
        const routeUrl = Router.getRouteUrl(id, possibleParams)

        if (routeUrl) {
          if (query.id) {
            yield call(ApiSagas.query, userQueries.markNotificationAsRead, { id: query.id })
          }

          yield call(redirect, null, routeUrl)
          return
        }
      }
    }
    yield Router.getRouteUrl(routes.userDashboard)
  }

  static* loop() {
    yield all([
      takeLatest(actions.init.getType(), EmailUserNotificationsSaga.init),
    ])
  }

}
