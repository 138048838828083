import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserProfile'

//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  updateProfile: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userProfile: setData', (data) => ({ data })),
  setLoaded: createAction('userProfile: setLoaded', (loaded) => ({ loaded })),
  //
  updateProfile: createAction('userProfile: updateProfile', (values) => ({ values })),
  updateProfileSuccess: createAction('userProfile: updateProfileSuccess', (data) => data),
  updateProfileError: createAction('userProfile: updateProfileError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
    [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
    ...requestReducers(actions, 'updateProfile'),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const updateProfile = (state) => root(state).updateProfile

export const selectors = {
  isLoaded,
  data,
  updateProfile,
}
