import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'postDetails'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,
}

//
// Actions
//
export const actions = {
  setSlug: createAction('Offer: setSlug', (slug) => ({ slug })),
  setData: createAction('Offer: setData', (data) => ({ data })),
  setLoaded: createAction('Offer: setLoaded', (loaded) => ({ loaded })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'updateSavedOffers'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data

export const selectors = {
  isLoaded,
  slug,
  data,
}
