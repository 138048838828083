import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


export const getNewEventById = (variables, headers) => query(gql`
  query getNewEventById($id: ID!) {
    getNewEventById(id: $id) {
        id
        label
        slug
        place
        date
        header_image
        begin_at
        end_at
        participants(first: 500) {
          paginatorInfo {
            currentPage
            lastPage
          }
          data {
            id
            is_check_in
            user {
              id
              first_name
              last_name
              companyFunction {
                name
              }
              company {
                name
              }
              avatar
              slug
              current_needs
            }
          }
        }
    }
  }
  `,
variables, headers)
