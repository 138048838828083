import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import withMemo from '../../decorators/WithMemo'


function JsonLd(props) {
  const { data } = props

  if (!data) {
    return null
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  )
}

JsonLd.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
}

JsonLd.defaultProps = {
  data: null,
}

export default withMemo()(JsonLd)
