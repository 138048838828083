import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'
import ApiSagas from '../../redux/api/sagas'
import { loadPublications } from '../UserPublications/queries'
import OfferCard from '../../data/models/OfferCard'
import userQueries from '../../graphql/query/User'

import { actions } from './redux'


export default class UserMessagesSagas {

  static* init(ctx) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, loadPublications)

    if (result.errors || !result.data) {
      yield put(hideLoading())
      return { error: 404 }
    }

    const offers = result.data?.me?.offers?.map(OfferCard)

    const seo = yield call(UserMessagesSagas.transformSeo)

    yield all([
      put(actions.setOffers(offers)),
      put(AppActions.setSeo(seo)),
      put(hideLoading()),
    ])

    return {}
  }

  static* getUsersByAutocompletion({ payload }) {
    if (!payload?.values?.value) {
      yield put(actions.setUsers([]))
      return
    }

    const res = yield call(ApiSagas.query, userQueries.byAutocomplete, {
      query: payload.values.value,
      onlyPremium: true,
      first: 50,
      page: 1,
    })

    const users = res?.data?.usersByAutocomplete?.data || []

    const formattedUsers = users.map((user) => ({
      ...user,
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      avatar: user.avatar || 'defaults/avatar',
      companyName: user?.company?.name || '',
    }))


    yield put(actions.setUsers(formattedUsers))
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const title = i18nStore[i18nLang]?.translation.SEO_messaging_title
    const description = i18nStore[i18nLang]?.translation.SEO_messaging_description

    const seoProps = {
      title,
      description,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userMessages)}`,
      noindex: true,
    }

    return seoProps
  }


  static* loop() {
    yield all([
      takeLatest(actions.getUsersByAutocompletion.getType(), UserMessagesSagas.getUsersByAutocompletion),
    ])
  }

}
