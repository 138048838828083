/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import app from '../../config/app'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { getFavorites } from './queries'
import { actions } from './redux'


export default class UserFavoriteSagas {

  // eslint-disable-next-line complexity
  static* init({ query }) {
    yield put(showLoading())

    const results = yield call(ApiSagas.query, getFavorites)

    if (results.errors || !results.data.me) {
      yield put(hideLoading())
      return { error: 404 }
    }

    yield put(actions.setData(results.data.me.favoritesUsers))

    const seo = yield call(UserFavoriteSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const title = i18nStore[i18nLang].translation.SEO_candidate_account_my_favorite_members_title
    const description = i18nStore[i18nLang].translation.SEO_candidate_account_my_favorite_members_description

    const seoProps = {
      title,
      image: '',
      description,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userFavorite)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
    ])
  }

}
