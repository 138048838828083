import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import { iconsKeys } from '../Icon/Icon.assets'
import { resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import { api } from '../../config'
import Icon from '../Icon'
import A from '../A'
import routes from '../../routes'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AdminPanel = (props) => {
  const preview = useSelector(AppSelectors.preview)
  const resource = useSelector(AppSelectors.resource)
  const route = useSelector(AppSelectors.route)
  const classes = useStyles(props)

  const allowedComponent = [
    routes.company.component,
    routes.offer.component,
    routes.page.component,
  ].indexOf(route.pathname) !== -1

  const key = {
    [resourceType.COMPANY]: 'companies',
    [resourceType.OFFER]: 'offers',
    [resourceType.PAGE]: 'pages',
  }[resource?.type]

  if (!preview || !resource?.id || !key || !allowedComponent) {
    return null
  }

  const url = `${api.BACKEND_URL}/resources/${key}/${resource.id}`

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <A
          href={url}
          target="_blank"
          className={classes.link}
          title="Admin Panel"
        >
          <Icon
            icon={iconsKeys.Edit}
            classname={classes.icon}
          />
        </A>
      </div>
    </div>
  )
}

export default withMemo()(AdminPanel)
