/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import Company from '../../data/models/Company'
import Share from '../../data/models/Share'
import companyQueries from '../../graphql/query/Company/index'
import { companyJsonLd } from '../../helpers/JsonLdHelpers'
import { assetUrl, routeParamSlug } from '../../helpers/UrlHelpers'
import i18n from '../../i18n'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions, pageType, resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'

import { actions, selectors } from './redux'


export default class CompanySagas {

  static* init({ asPath, query, res }) {
    return yield call(CompanySagas.loadCompany, query, asPath, res)
  }

  static* loadCompany({ slug = '/' }, asPath, res) {
    yield put(showLoading())
    const errors = []

    // Company
    const result = yield call(ApiSagas.query, companyQueries.byFullSlug, { slug })

    // Check if errors
    if (result.errors && result.errors.length) {
      result.errors.map((error) => errors.push(error))
    }

    const returnedData = yield call(CompanySagas.transformData, result.data?.companyByFullSlug)
    const breadCrumb = yield call(CompanySagas.transformBreadcrumb, returnedData)
    const jsonld = yield call(CompanySagas.transformJsonld, returnedData)
    const seo = yield call(CompanySagas.transformSeo, returnedData)

    yield all([
      put(actions.setData(returnedData)),
      put(AppActions.setResource(resourceType.COMPANY, result.data?.companyByFullSlug?.id)),
      put(AppActions.setBreadcrumb(breadCrumb)),
      put(AppActions.setJsonld(jsonld)),
      put(AppActions.setSeo(seo)),
    ])

    yield all([
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    // Check errors
    if (!result.data?.companyByFullSlug && errors && errors.length >= 1) {
      console.log('[COMPANY] 404 error loading data', errors)
      yield put(hideLoading())
      return { error: 404 }
    }

    if (returnedData === null) {
      console.log('[COMPANY] 404 error loading data', result)
      yield put(hideLoading())
      return { error: 404 }
    }
  }

  static transformData(data) {
    if (!data) {
      return null
    }

    const company = Company(data)

    return {
      ...data,
      company,
    }
  }

  static transformComment(data) {
    if (!data) {
      return null
    }

    return data
  }

  static* transformBreadcrumb(data) {
    if (data) {
      const pages = yield select(AppSelectors.pages)

      return [
        {
          label: pages[pageType.HOME].title,
          title: pages[pageType.HOME].title,
          link: pages[pageType.HOME].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.HOME].link) },
        },
        {
          label: pages[pageType.DIRECTORY].title,
          title: pages[pageType.DIRECTORY].title,
          link: pages[pageType.DIRECTORY].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.DIRECTORY].link) },
        },
        {
          label: data.company.title,
          title: data.company.title,
        },
      ]
    }

    return []
  }

  static transformJsonld(data) {
    if (data) {
      return companyJsonLd(data)
    }

    return {}
  }

  static* postComment({ payload }) {
    const values = payload.values

    const data = yield select(selectors.data)

    const input = {
      company: data.company.id,
      content: values.content,
      recommendation: values.recommendation,
    }

    const result = yield call(ApiSagas.query, companyQueries.postComment, { input })

    if (result.errors && result.errors.length) {
      yield put(actions.postCommentError(result.errors))
    } else {
      const d = yield call(CompanySagas.transformComment, result.data?.createComment)

      yield put(actions.setData({
        ...data,
        company: {
          ...data.company,
          comments: (data.company.comments).concat(result.data?.createComment),
        },
      }))
      yield put(actions.postCommentSuccess(d))
      yield put(actions.deleteCommentReset())
    }
  }

  static* deleteComment({ payload }) {
    const values = payload.values

    const data = yield select(selectors.data)

    const result = yield call(ApiSagas.query, companyQueries.deleteComment, { id: values.comment?.id })

    if (result.errors && result.errors.length) {
      yield put(actions.deleteCommentError(result.errors))
    } else {
      const d = yield call(CompanySagas.transformComment, result.data?.deleteComment)

      yield put(actions.setData({
        ...data,
        company: {
          ...data.company,
          comments: data.company.comments.filter((comment) => comment.id !== result.data?.deleteComment.id),
        },
      }))
      yield put(actions.deleteCommentSuccess(d))
    }
  }

  static transformSeo(data) {
    if (data && data.share) {
      const transformedShare = Share(data.share)
      const title = i18n.t('SEO_company_title', { name: data.name })
      const description = i18n.t('SEO_company_description', { name: data.name })

      return {
        ...transformedShare,
        title,
        description,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.company, { slug: data.full_slug })}`,
        image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
        noindex: false,
      }
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(actions.postComment.getType(), CompanySagas.postComment),
      takeLatest(actions.deleteComment.getType(), CompanySagas.deleteComment),
    ])
  }

}
