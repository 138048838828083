import PropTypes from 'prop-types'

import Domain from './Domain'


const Sector = (props) => (props && {
  id: props.id,
  name: props.name,
  slug: props.slug,
  domains: props?.domains?.map(Domain),
})

Sector.propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  domains: PropTypes.arrayOf(Domain.propTypes),
})

export default Sector
