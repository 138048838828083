import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import Candidacy from '../../data/models/Candidacy'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'

import { actions } from './redux'
import { getMyCandidacies } from './queries'


export default class UserCandidaciesSagas {

  static* init({ asPath, query, res }) {
    return yield call(UserCandidaciesSagas.loadPage, query, asPath, res)
  }

  static* loadPage({ slug = '/' }, asPath, res) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, getMyCandidacies)

    if (result.data?.me) {
      const me = result.data.me

      const candidacies = me?.candidacies?.data?.map(Candidacy)

      yield put(actions.setData(candidacies))
    }

    const seo = yield call(UserCandidaciesSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const title = i18nStore[i18nLang]?.translation.SEO_candidacies_title
      const description = i18nStore[i18nLang]?.translation.SEO_candidacies_description

      const seoProps = {
        title,
        description,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userCandidacies)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }


  static* loop() {
    yield all([
      //
    ])
  }

}
