import { colors, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    width: '500px',
    height: '25px',
    backgroundColor: colors.darkOrange,
    fontFamily: fonts.FiraSansExtraLight.fontFamily,
    fontWeight: fontWeights.medium,
    fontSize: '1.5rem',
    textAlign: 'center',
    lineHeight: '25px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}
