import { iconsKeys } from '../components/Icon/Icon.assets'


export const mapIconApiTypeToFrontKey = (iconType) => {
  switch (iconType) {
    case 'FACEBOOK':
      return iconsKeys.FacebookIcon
    case 'TWITTER':
      return iconsKeys.TwitterIcon
    case 'LINKEDIN':
      return iconsKeys.LinkedInIcon
    case 'YOUTUBE':
      return iconsKeys.YoutubeIcon
    case 'INSTAGRAM':
      return iconsKeys.InstagramIcon
    default:
      return ''
  }
}
