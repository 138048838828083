import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import Candidacy from '../../data/models/Candidacy'
import OfferCard from '../../data/models/OfferCard'
import userQueries from '../../graphql/query/User'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import User from '../../data/models/User'

import { getDashboardData } from './queries'
import { actions } from './redux'


export default class UserDashboardSagas {

  static* init(ctx) {
    yield put(showLoading())


    const user = yield select(AuthSelectors.user)

    const result = yield call(ApiSagas.query, getDashboardData)

    if (result.data?.me) {
      const me = result.data.me

      if (me?.notifications) {
        yield put(AuthActions.setUserNotifications(User.transformNotifications(user, me.notifications)))
      }

      const candidacies = me?.candidacies?.data?.map(Candidacy)
      const offers = me?.offers?.map(OfferCard)

      yield put(actions.setData({ candidacies, offers }))
    }

    const seo = yield call(UserDashboardSagas.transformSeo, { isCompany: user.isCompany })

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const candidateTitleLabel = i18nStore[i18nLang]?.translation.SEO_candidate_dashboard_title
      const candidateDescriptionLabel = i18nStore[i18nLang]?.translation.SEO_candidate_dashboard_description
      const companyTitleLabel = i18nStore[i18nLang]?.translation.SEO_company_dashboard_title
      const companyDescriptionLabel = i18nStore[i18nLang]?.translation.SEO_company_dashboard_description
      const isCompany = data?.isCompany

      const seoProps = {
        title: isCompany ? companyTitleLabel : candidateTitleLabel,
        image: '',
        description: isCompany ? companyDescriptionLabel : candidateDescriptionLabel,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userDashboard)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }

  static* markNotificationAsRead({ payload }) {
    const values = payload.values
    const id = values.id
    const user = yield select(AuthSelectors.user)

    const result = yield call(ApiSagas.query, userQueries.markNotificationAsRead, { id })

    if (result.errors && result.errors.length) {
      yield put(actions.markNotificationAsReadError(result.errors))
    } else {
      const notifications = user?.notifications

      const newNotifs = notifications.filter((n) => n.id !== id)

      yield all([
        put(AuthActions.setUserNotifications(User.transformNotifications(user, newNotifs))),
        put(actions.markNotificationAsReadSuccess()),
      ])
    }
  }

  static* loop() {
    yield all([
      takeLatest(actions.markNotificationAsRead.getType(), UserDashboardSagas.markNotificationAsRead),
    ])
  }

}
