import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import { app } from '../../config'

import { actions } from './redux'
import { getResources } from './queries'


export default class UserResourcesSagas {

  static* init({ asPath, query, res }) {
    return yield call(UserResourcesSagas.loadPage, query, asPath, res)
  }

  static* loadPage(asPath) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, getResources, {
      first: 7,
      page: asPath.page,
      orderBy: [
        {
          field: 'created_at',
          order: 'DESC',
        },
      ],
    })

    const seo = yield call(UserResourcesSagas.transformSeo)

    if (result.data.means) {
      const data = result.data.means.data
      const pagination = result.data.means.paginatorInfo

      yield put(actions.setData(data))
      yield put(actions.setPagination(pagination))
      yield put(AppActions.setSeo(seo))
    }

    if (result.errors || !result.data.means) {
      yield put(hideLoading())
      return { error: 404 }
    }

    yield all([put(hideLoading())])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const titleLabel = i18nStore[i18nLang].translation.SEO_user_ressources_title
    const descriptionLabel = i18nStore[i18nLang].translation.SEO_user_ressources_description

    const seoProps = {
      title: titleLabel,
      image: '',
      description: descriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userResources)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
