/* eslint-disable no-console */
import '../src/utils/polyfills'
import withRedux from 'next-redux-wrapper'
import React, { useEffect, useMemo } from 'react'
import { JssProvider, ThemeProvider } from 'react-jss'
import { Provider } from 'react-redux'

import AppGate from '../src/containers/AppGate'
import { I18nProvider } from '../src/i18n'
import createStore from '../src/redux'
import AppSagas from '../src/redux/app/sagas'
import getPageContext from '../src/theme/context'
import { app } from '../src/config'


const MyApp = (props) => {
  // eslint-disable-next-line react/prop-types
  const { store, ...pageProps } = props

  const pageContext = useMemo(getPageContext, [])

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    if (app.UNREGISTER_SW) {
      try {
        console.log('Unregistering service worker')
        // eslint-disable-next-line no-unused-expressions
        navigator?.serviceWorker?.getRegistrations().then((registrations) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      } catch (e) {
        console.log('Service worker unregister error', e)
      }
    }
  }, [])

  return (
    <>
      <JssProvider
        registry={pageContext.sheetsRegistry}
        generateClassName={pageContext.generateClassName}
        jss={pageContext.jss}
        generateId={pageContext.generateId}
      >
        <ThemeProvider theme={pageContext.theme}>
          <Provider store={store}>
            <I18nProvider store={store}>
              <AppGate
                pageContext={pageContext}
                {...pageProps}
              />
            </I18nProvider>
          </Provider>
        </ThemeProvider>
      </JssProvider>
    </>
  )
}

MyApp.getInitialProps = async({ Component, ctx }) => {
  try {
    const props = await ctx.store.saga
      .run(AppSagas.init, ctx, Component?.getInitialProps)
      .done

    return props
  } catch (e) {
    console.error(e)
  }
}

export default withRedux(createStore)(MyApp)
