import { call } from 'redux-saga/effects'

import ApiSagas from '../redux/api/sagas'


export default class GraphApi {

  static* query({ query, transformer, variables }) {
    const result = yield call(ApiSagas.query, query, variables)
    const transformed = yield call(GraphApi.transform, result, transformer)

    return { ...result, transformed }
  }

  static transform(result, transform) {
    if (!transform || !result.data) {
      return null
    }

    return transform(result.data)
  }

}
