import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'UserCandidacy'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  deleteCandidacy: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userCandidacy: setData', (data) => ({ data })),
  setLoaded: createAction('userCandidacy: setLoaded', (loaded) => ({ loaded })),
  //
  deleteCandidacy: createAction('userCandidacy: deleteCandidacy', (values) => (values)),
  deleteCandidacyError: createAction('userCandidacy: deleteCandidacyError', (errors) => Errors(errors)),
  deleteCandidacySuccess: createAction('userCandidacy: deleteCandidacySuccess', (data) => ({ data })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'deleteCandidacy'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const deleteCandidacy = (state) => root(state).deleteCandidacy

export const selectors = {
  isLoaded,
  data,
  deleteCandidacy,
}
