import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserEvent'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  //
  updateFavoriteUser: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userEvent: setData', (data) => ({ data })),
  setLoaded: createAction('userEvent: setLoaded', (loaded) => ({ loaded })),
  //
  updateFavoriteUser: createAction('userEvent: updateFavoriteUser', (data) => ({ data })),
  updateFavoriteUserSuccess: createAction('userEvent: updateFavoriteUserSuccess', (data) => data),
  updateFavoriteUserError: createAction('userEvent: updateFavoriteUserError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  //
  ...requestReducers(actions, 'updateFavoriteUser'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const updateFavoriteUser = (state) => root(state).updateFavoriteUser

export const selectors = {
  isLoaded,
  data,
  updateFavoriteUser,
}
