import { all, call, put, select, takeLatest, take } from 'redux-saga/effects'
import Immutable from 'seamless-immutable'

import i18next from '../../i18n'
import ApiSagas from '../api/sagas'
import Translations from '../../graphql/query/Translations'
import { labelTypes } from '../../data/enums/labelTypes'

import { actions as TranslationActions, selectors as TranslationsSelectors } from './redux'


export default class TranslationSagas {

  static* init(lang) {
    const initLang = yield select(TranslationsSelectors.lang)

    if (!process.browser) {
      i18next.reset()
      yield put(TranslationActions.saveLang(lang, {}))
    }

    yield call(TranslationSagas.getLocales)
    yield put(TranslationActions.setLang(lang || initLang))
    yield take(TranslationActions.saveLang.getType())
  }

  static* getLocales() {
    let locales = yield select(TranslationsSelectors.locales)

    if (!locales) {
      const result = yield call(ApiSagas.query, Translations.locales)

      if (!result.errors) {
        locales = result.data.locales
      } else {
        console.error('Locales not loaded successfully')
      }
    }

    yield put(TranslationActions.setLocales(locales))
  }

  static* setLang({ payload }) {
    let { lang } = payload

    if (!i18next.locales[lang]) {
      lang = i18next.fallbackLng
    }

    yield call(TranslationSagas.loadLang, lang)

    i18next.changeLanguage(lang)

    yield put(TranslationActions.saveLang(lang, i18next.store.data))
  }

  static* loadLang(lang) {
    const configuration = Immutable.asMutable(yield select(TranslationsSelectors.configuration))
    const store = Immutable.asMutable(yield select(TranslationsSelectors.store))

    if (!store[lang]) {
      const apiTranslations = {}
      const localeConfiguration = {}
      const result = yield call(ApiSagas.query, Translations.labels, { localeFilters: { locale: lang } })

      if (!result.errors) {
        result.data.labels.forEach((label) => {
          if (label.type === labelTypes.TEXT) {
            apiTranslations[label.key] = label.value
          } else {
            localeConfiguration[label.key] = {
              type: label.type,
              value: label.value,
            }
          }
        })

        store[lang] = {
          translation: {
            ...i18next.locales[lang],
            ...apiTranslations,
          },
        }

        configuration[lang] = localeConfiguration
      } else {
        console.error('Translations not loaded successfully')
      }
    }

    i18next.addResourceBundle(lang, 'translation', store[lang].translation)

    yield put(TranslationActions.setStore(store))
    yield put(TranslationActions.setConfiguration(configuration))
  }

  static* loop() {
    yield all([
      takeLatest(TranslationActions.setLang.getType(), TranslationSagas.setLang),
    ])
  }

}
