import PropTypes from 'prop-types'


const CompanyFunction = (props) => (props && {
  id: props.id,
  name: props.name,
})

CompanyFunction.propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
})

export default CompanyFunction
