import { getCloudinaryUrl } from '../../helpers/CloudinaryHelpers'
import { mapIconApiTypeToFrontKey } from '../../helpers/MappingHelpers'
import { getGoogleMapApiUrl, getYoutubeThumbnailUrl } from '../../helpers/UrlHelpers'
import linkType from '../enums/linkType'

import Comment from './Comment'
import FileEntry from './FileEntry'
import OfferCard from './OfferCard'


const Company = (props) => {
  if (!props) {
    return null
  }

  const domains = []

  domains.push(...props.domains || null)

  let map = null

  let addresses = []

  if (props.location) {
    addresses.push({
      street: `${props.location.address}${props.location.address_2 ? `<br>${props.location.address_2}` : ''}`,
      zip: props.location.zip_code,
      city: props.location.city,
      isMain: true,
      link: getGoogleMapApiUrl(props.location.lat, props.location.long),
    })
    if (props.location.image) {
      map = {
        link: getGoogleMapApiUrl(props.location.lat, props.location.long),
        image: getCloudinaryUrl(props.location.image),
      }
    }
  }

  let address = null

  if (props.location) {
    address = {
      street: `${props.location.address}${props.location.address_2 ? `<br>${props.location.address_2}` : ''}`,
      zip: props.location.zip_code,
      city: props.location.city,
      link: getGoogleMapApiUrl(props.location.lat, props.location.long),
      image: getCloudinaryUrl(props.location.image),
    }
  }

  if (props.locations) {
    addresses = [
      ...addresses,
      ...props.locations.filter((location) => !location.is_main).map((location) => ({
        name: location.name,
        street: `${location.address}${location.address_2 ? `<br>${location.address_2}` : ''}`,
        zip: location.zip_code,
        city: location.city,
        link: getGoogleMapApiUrl(location.lat, location.long),
      })),
    ]
  }

  let banner = 'defaults/company-banner'

  let pictures = []

  if (props.pictures) {
    pictures = props.pictures.sort((a, b) => ((a.order > b.order) ? 1 : -1)).filter((picture) => {
      if (picture.is_main) {
        banner = picture.image
        return false
      }
      return true
    }).map((picture) => ({
      id: picture.id,
      picture: picture.image,
    }))
  }

  let files = []

  if (props.files) {
    files = props.files.map(FileEntry)
  }

  let website = null

  let links = null

  if (props.links) {
    links = props.links.filter((link) => {
      if (link.type === linkType.WEBSITE) {
        website = link.value
        return false
      }
      return true
    }).map((link) => ({
      id: link.id,
      icon: mapIconApiTypeToFrontKey(link.type),
      link: link.value,
    }))
  }

  let video = null

  if (props.video) {
    video = {
      thumbnail: getYoutubeThumbnailUrl(props.video),
      video: props.video,
    }
  }

  let offers = null

  if (props.offers) {
    offers = {
      cards: props.offers.map((offer) => OfferCard(offer)),
    }
  }

  const customCertifications = props.custom_certifications?.split(';').join().split(',').map((certification, key) => (
    {
      id: `${-key}`,
      name: certification,
    }
  ))

  const company = {
    id: props.id,
    slug: props.full_slug,
    title: props.name,
    description: props.description,
    logo: props.logo,
    phone: props.phone,
    comments: props.comments?.map(Comment),
    certifications: props.certifications?.concat(customCertifications ?? []) ?? [],
    plainCertifications: props.plain_certifications ?? [],
    references: props.references ?? [],
    contactMembers: props.contactMembers,
    isActivePremium: props.isActivePremium,
    contacts: props.contacts,
    domains: domains.filter((domain) => !!domain?.name),
    address,
    addresses,
    map,
    video,
    links,
    banner,
    cover: banner,
    gallery: { pictures },
    files: { files },
    website,
    offers,
    metrics: [
      {
        value: props.creation_year,
        label: 'creation_year',
      },
      {
        value: props.workforce,
        label: 'workforce',
      },
      {
        value: props.offers_count,
        label: 'offers',
      },
    ].filter((metric) => !!metric.value),
    turnover: props?.turnover ?? null,
  }

  return company
}

export default Company
