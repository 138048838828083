import { createAction, createReducer } from 'redux-act'


export const key = 'EmailVerification'


//
// Initial state
//
const initialState = {
}

//
// Actions
//
export const actions = {
  init: createAction('EmailVerification: init'),
  redirect: createAction('EmailVerification: redirect'),
}

//
// Reducer
//
export const reducer = createReducer({
}, initialState)

//
// Selectors
//

export const selectors = {
}
