import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserPublications'


//
// Initial state
//
const initialState = {
  slug: null,
  offers: null,
  posts: null,
  loaded: false,
  deletePost: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('Page: setSlug', (slug) => ({ slug })),
  setOffers: createAction('Page: setOffers', (offers) => ({ offers })),
  setPosts: createAction('Page: setPosts', (posts) => ({ posts })),
  setLoaded: createAction('Page: setLoaded', (loaded) => ({ loaded })),
  //
  deletePost: createAction('userPost: deletePost', (values) => (values)),
  deletePostError: createAction('userPost: deletePostError', (errors) => Errors(errors)),
  deletePostSuccess: createAction('userPost: deletePostSuccess', (data) => ({ data })),
  deletePostReset: createAction('userPost: deleteCommentReset'),
  //
  downloadOffers: createAction('userPost: downloadOffers', (data) => ({ data })),

}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setOffers]: (state, { offers }) => Immutable.static.merge(state, { offers }),
  [actions.setPosts]: (state, { posts }) => Immutable.static.merge(state, { posts }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'deletePost'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const offers = (state) => root(state).offers
const posts = (state) => root(state).posts
const deletePost = (state) => root(state).deletePost

export const selectors = {
  isLoaded,
  slug,
  offers,
  posts,
  deletePost,
}
