import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'
import { OfferCardFragment } from '../../graphql/query/Fragments/OfferCardFragment'


export const getDashboardData = (variables, headers) => query(gql`
    query getDashboardData {
        me {
            notifications {
                id
                type
                message
                payload
                offer_id
            }
            candidacies(first:10) {
                data{
                    id
                    applied_at
                    offer {
                        ...offerCard
                    }
                }
            }
            offers {
                ...offerCard
            }
        }
    }
    ${OfferCardFragment.fragment}
  `,
variables, headers)
