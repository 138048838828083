import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const offerFragment = gql`
    fragment offerPublishFragment on Offer {
        id
        reference
        label
        maximal_execution_at
        introduction
        description
        budget
        type
        link
        company_name
        files {
            id
            preview_url
            pdf_url
            title
        }
        city
        categories {
            id
        }
        keywords
    }
`

export const getOffer = (variables, headers) => query(gql`
    query ownedOfferByReference($reference: String!) {
        offer:ownedOfferByReference(reference: $reference) {
            ...offerPublishFragment
        }
    }
    ${offerFragment}
  `,
variables, headers)


export const createOffer = (variables, headers) => query(gql`
    mutation createOffer(
        $input: CreateOfferInput!
    ) {
        offer:createOffer(input: $input) {
            ...offerPublishFragment
        }
    }
    ${offerFragment}
`, variables, headers)

export const updateOffer = (variables, headers) => query(gql`
    mutation updateOffer(
        $id: ID!
        $input: UpdateOfferInput!
    ) {
        offer:updateOffer(id: $id, input: $input) {
            ...offerPublishFragment
        }
    }
    ${offerFragment}
`, variables, headers)
