import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import OfferCard from '../../data/models/OfferCard'
import Post from '../../data/models/Post'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import { selectors as AuthSelectors } from '../../redux/auth/redux'

import { deletePost, loadPublications } from './queries'
import { actions, selectors } from './redux'


export default class UserPublicationsSagas {

  static* init({ asPath, query, res }) {
    return yield call(UserPublicationsSagas.loadPage, query, asPath, res)
  }

  static* loadPage({ slug = '/' }, asPath, res) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, loadPublications)

    if (result.errors || !result.data) {
      yield put(hideLoading())
      return { error: 404 }
    }

    const offers = result.data?.me?.offers?.map(OfferCard)
    const posts = result.data?.me?.posts?.data?.map(Post)

    yield put(actions.setOffers(offers))
    yield put(actions.setPosts(posts))

    const seo = yield call(UserPublicationsSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_publications_title
    const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_publications_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPublishOffer)}`,
      noindex: true,
    }

    return seoProps
  }

  static* deletePost({ payload }) {
    const result = yield call(ApiSagas.query, deletePost, { id: payload })

    if (result.errors) {
      console.log('UserPublications: Errors', result.errors)
      yield put(actions.deletePostError(result.errors))
    } else {
      const posts = yield select(selectors.posts)
      const newPosts = Array.from(posts).filter((post) => post.id !== payload)

      yield put(actions.setPosts(newPosts))
      yield put(actions.deletePostSuccess(result.data.deletePost))
    }
  }


  static* downloadOffers() {
    const user = yield select(AuthSelectors.user)
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)

    const url = `/api/export/offer/${user.id}/offers`
    const data = yield call(ApiSagas.fetch, url)
    const blob = yield data.blob()
    const urlBlob = window.URL.createObjectURL(blob)
    const a = document.createElement('a')

    a.href = urlBlob
    a.download = i18nStore[i18nLang].translation.offers_download_file_name
    document.body.appendChild(a)
    a.click()
    a.remove()
  }


  static* loop() {
    yield all([
      takeLatest(actions.deletePost.getType(), UserPublicationsSagas.deletePost),
      takeLatest(actions.downloadOffers.getType(), UserPublicationsSagas.downloadOffers),
    ])
  }

}
