import { colors, fonts, tablet, desktop, sizes, transition } from '../../theme'


export default {
  container: {
    position: 'fixed',
    zIndex: 9000,
    top: 0,
    left: 0,
    color: colors.nightRider,
    width: '100%',
    height: '100%',

    '& .gdpr-settings': {
      position: 'fixed',
      zIndex: 9000,
      color: colors.orange,
      width: '100%',
      height: '100%',

      '& .gdpr-overlay': {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
      },

      '& .gdpr-popin': {
        backgroundColor: colors.pureWhite,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        transform: 'none',
        ...tablet({
          maxWidth: 'calc(100% - 4rem)',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }),
        ...desktop({
          maxWidth: `calc(${sizes.desktop / 10}rem - 4rem)`,
        }),
      },

      '& .gdpr-container': {
        position: 'absolute',
        left: '2rem',
        right: '2rem',
        top: '2rem',
        bottom: '4rem',
        display: 'flex',
        flexDirection: 'column',
        ...tablet({
          left: '6rem',
          right: '6rem',
          top: '6rem',
          bottom: '6rem',
        }),

        '& .gdpr-content-wrapper': {
          width: '100%',
          height: '100%',
          overflow: 'scroll',
          flex: 1,

          '& .gdpr-content': {
            paddingRight: '2rem',
          },
        },
      },

      '& .gdpr-title': {
        ...fonts.FiraSansBold,
        fontSize: '2.2rem',
        lineHeight: '2.7rem',
        color: colors.darkBlue,
        textTransform: 'uppercase',
        margin: 0,
        marginBottom: '2rem',
      },

      '& .gdpr-description': {
        ...fonts.FiraSansRegular,
        color: colors.nightRider,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        marginBottom: '6rem',
      },

      '& .gdpr-section': {
        borderBottom: `solid .1rem ${colors.silver}`,
        marginBottom: '3rem',
        position: 'relative',
        paddingBottom: '2rem',
        ...tablet({
          paddingBottom: 0,
        }),

        '& .gdpr-section-title': {
          fontSize: '1.8rem',
          marginBottom: '2rem',
        },

        '& .gdpr-section-description': {
          fontSize: '1.6rem',
          marginBottom: '2rem',
        },
      },

      '& .gdpr-toggle': {
        top: 0,
        right: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '1.5rem',
        position: 'relative',
        justifyContent: 'flex-end',
        ...tablet({
          marginTop: 0,
          position: 'absolute',
        }),

        '& .gdpr-toggle-track': {
          width: '4.8rem',
          height: '2.3rem',
          backgroundColor: colors.silver,
          borderRadius: '3.5rem',
          position: 'relative',
          transition: 'all 0.3s',
        },

        '& .gdpr-toggle-cursor': {
          width: '1.7rem',
          height: '1.7rem',
          borderRadius: '50%',
          backgroundColor: colors.pureWhite,
          position: 'absolute',
          top: '.3rem',
          left: '.3rem',
          transition: 'all 0.3s',
        },

        '& .gdpr-toggle-status': {
          ...fonts.FiraSansMedium,
          fontSize: '1.6rem',
          marginRight: '2rem',
          transition: 'all 0.3s',
          color: colors.silver,
        },

        '&.validated': {
          '& .gdpr-toggle-track': {
            backgroundColor: colors.darkBlue,
          },
          '& .gdpr-toggle-cursor': {
            backgroundColor: colors.pureWhite,
            left: '2.8rem',
          },
          '& .gdpr-toggle-status': {
            color: colors.orange,
          },
        },
      },

      '& .gdpr-footer': {
        marginTop: '1.5rem',
        paddingRight: '1.5rem',
        textAlign: 'right',
      },

      '& .gdpr-button': {
        display: 'inline-flex',
        extend: transition('all', '0.3s'),
        padding: '1rem 2rem',
        borderRadius: '2rem',
        fontFamily: fonts.FiraSansRegular.fontFamily,
        fontSize: '1.4rem',
        lineHeight: '1.7rem',
        color: colors.pureWhite,
        marginLeft: '1rem',
        cursor: 'pointer',
        backgroundColor: colors.darkBlue,
        '&:hover': {
          backgroundColor: colors.darkBlue,
        },
      },
    },
  },
}
