import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0 4rem',
    width: '100%',
    margin: '0 auto',
    backgroundColor: colors.pureWhite,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '75%',
  },
  text: {
    textAlign: 'center',
  },
  title: {
    display: 'inline',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.6rem',
    lineHeight: '2rem',
    textAlign: 'center',
    width: '100%',
    ...tablet({
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    }),
  },
  subtitle: {
    display: 'inline',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.6rem',
    lineHeight: '2rem',
    textAlign: 'center',
    width: '100%',
    ...tablet({
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    }),
  },
  button: {
    display: 'block',
    marginTop: '1rem',
  },
  updateButton: {
    marginRight: '1rem',
  },
}
