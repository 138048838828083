import gql from 'graphql-tag'


export const Locales = gql`    
    query locales {
        locales {
            iso
            label
            fallback {
                iso
            }
        }
    }
`
