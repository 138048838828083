import { colors, desktop } from '../../theme'


export const themes = {
  darkOrange: 'darkOrange',
  neonBlue: 'neonBlue',
}


const theming = {
  loader: {
    backgroundColor: {
      [themes.darkOrange]: colors.darkOrange,
      [themes.neonBlue]: colors.nightRider,
    },
  },
}

export default {
  loaderContainer: {
    position: 'fixed',
    width: '100%',
    margin: '0 auto',
    height: '0.3rem',
    zIndex: '999',
    left: 0,
    top: '6.4rem',
    overflow: 'hidden',
    ...desktop({
      top: '8rem',
    }),
  },
  loader: {
    position: 'absolute',
    height: '0.3rem',
    width: '100%',
    backgroundColor: (props) => theming.loader.backgroundColor[props.theme],
  },
}
