import gql from 'graphql-tag'

import { ShareFragment } from '../Fragments/ShareFragment'
import ParagraphBlockFragment from '../../../blocks/ParagraphBlock/fragment'
import ImageBlockFragment from '../../../blocks/ImageBlock/fragment'


export const ArticleBySlug = gql`
    query ArticleBySlug($slug: String!) {
        articleBySlug(slug: $slug) {
            title
            slug
            category {
              name
              theme
              bgColor
            }
            description
            picture
            credits
            is_highlighted
            blocks {
                __typename
                ...ParagraphBlockFragment
                ...ImageBlockFragment
            }
            author {
              first_name
              last_name
              company
            }
            published_at
            previous {
              title
              slug
            }
            next {
              title
              slug
            }
            created_at
            updated_at
            share {
                ...share
            }
            linked_articles {
              title
              slug
              category {
                name
                theme
                bgColor
              }
              description
              picture
              is_highlighted
            }
            documentPdf
        }
    }

    ${ShareFragment.fragment}
    ${ParagraphBlockFragment}
    ${ImageBlockFragment}
`
