import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


export const getFavorites = (variables, headers) => query(gql`
    query Me {
        me {
          favoritesUsers {
              id
              first_name
              last_name
              companyFunction {
                name
              }
              company {
                name
              }
              avatar
              current_needs
            }
        }
    }
  `,
variables, headers)


