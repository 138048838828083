import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import _values from 'lodash/values'
// eslint-disable-next-line
import { default as Loader } from 'react-redux-loading-bar'

import withMemo from '../../decorators/WithMemo'

import styles, { themes } from './styles'


const useStyles = createUseStyles(styles)

function LoadingBar(props) {
  const classes = useStyles(props)
  const { classNames } = props

  return (
    <div className={classes.loaderContainer}>
      <Loader
        updateTime={100}
        progressIncrease={8}
        maxProgress={100}
        className={cx(classes.loader, classNames.loader)}
      />
    </div>
  )
}

LoadingBar.styles = styles

LoadingBar.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line
  theme: PropTypes.oneOf(_values(themes)),
}

LoadingBar.defaultProps = {
  classNames: {},
  theme: themes.neonBlue,
}

export default withMemo()(LoadingBar)
