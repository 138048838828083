/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { cookiesTool } from 'react-gdpr'

import { actions as AppActions, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as TrackingActions, selectors as TrackingSelectors } from '../../redux/tracking/redux'
import { Router } from '../../routes'

import GDPRBanner from './index'


function GDPRBannerWrapper() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [allowRender, setAllowRender] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const cookiesSettings = useSelector(AppSelectors.cookiesSettings)
  const enabled = useSelector(TrackingSelectors.enabled)

  function handleOpenSettings() {
    dispatch(AppActions.openCookiesSettings())
  }

  function handleRefuse() {
    cookiesTool.setActive('analytics', false)
    cookiesTool.setActive('ads', false)
    cookiesTool.setActive('cookiesEdited', true)
    dispatch(TrackingActions.ad(false))
    setIsHidden(true)
  }

  function handleAccept() {
    cookiesTool.setActive('analytics', true)
    cookiesTool.setActive('ads', true)
    cookiesTool.setActive('cookiesEdited', true)
    setIsHidden(true)
    dispatch(TrackingActions.enableTracking())
    dispatch(TrackingActions.ad(true))
  }

  function handleCookieStatement() {
    Router.pushRoute(t('GDPR_BANNER_politicLink'))
  }

  function handleKnowMore() {
    Router.pushRoute(t('GDPR_BANNER_knowMoreLink'))
  }

  useEffect(() => {
    setAllowRender(true)

    if (cookiesSettings || enabled) {
      setIsHidden(true)
    }
  }, [cookiesSettings, enabled])

  const GDPRBannerProps = {
    textProps: {
      title: t('GDPR_BANNER_title'),
      description: t('GDPR_BANNER_description'),
      politicLabel: t('GDPR_BANNER_politicLabel'),
      preferencesLabel: t('GDPR_BANNER_preferencesLabel'),
      acceptLabel: t('GDPR_BANNER_acceptLabel'),
      refuseLabel: t('GDPR_BANNER_refuseLabel'),
      knowMoreLabel: t('GDPR_BANNER_knowMoreLabel'),
    },
  }

  if (!allowRender || isHidden || cookiesTool.isActive('cookiesEdited')) {
    return null
  }

  return (
    <GDPRBanner
      {...GDPRBannerProps}
      handleAccept={handleAccept}
      handleRefuse={handleRefuse}
      handleCookieStatement={handleCookieStatement}
      handleOpenSettings={handleOpenSettings}
      handleKnowMore={handleKnowMore}
    />
  )
}

GDPRBannerWrapper.propTypes = GDPRBanner.propTypes

GDPRBannerWrapper.defaultProps = GDPRBanner.defaultProps

export default GDPRBannerWrapper
