import { query } from '../../../helpers/GraphqlHelpers'

import { Labels } from './Labels'
import { Locales } from './Locales'


export default {
  labels: (variables, headers = {}) => query(Labels, variables, headers),
  locales: (variables, headers = {}) => query(Locales, variables, headers),
}
