import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'UserResources'


//
// Initial state
//
const initialState = {
  data: null,
  pagination: null,
  page: null,
}

//
// Actions
//
export const actions = {
  setData: createAction('Page: setData', (data) => ({ data })),
  setPagination: createAction('Page: setPagination', (pagination) => ({ pagination })),
  setPage: createAction('Page: setPage', (page) => ({ page })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setPagination]: (state, { pagination }) => Immutable.static.merge(state, { pagination }),
  [actions.setPage]: (state, { page }) => Immutable.static.merge(state, { page }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const data = (state) => root(state).data
const pagination = (state) => root(state).pagination
const page = (state) => root(state).page

export const selectors = {
  data,
  pagination,
  page,
}
