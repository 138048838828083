import gql from 'graphql-tag'

import { PostCardFragment } from '../../graphql/query/Fragments/PostCardFragment'
import { query } from '../../helpers/GraphqlHelpers'


export const loadPublications = (variables, headers) => query(gql`
    query loadPublications {
        me {
            offers {
                id
                reference
                label
                maximal_execution_at
                city
                published_at
                user {
                    first_name
                    last_name
                }
                categories {
                    id
                    name
                    color
                }
            }
            
            posts(first: 500) {
                data {
                    ...postCard
                }
            }
        }
    }
    
    ${PostCardFragment.fragment}
  `,
variables, headers)

export const deletePost = (variables, headers) => query(gql`
    mutation deletePost($id: ID!) {
        deletePost(id: $id) {
            id
        }
    }
  `,
variables, headers)
