import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const companyFragment = gql`
    fragment companyFragment on Company{
        id
        full_slug
        name
        siret
        location_name
        description
        logo
        video
        creation_year
        workforce
        turnover
        isActivePremium
        phone
        email
        contacts {
            id
            email
            phone
            first_name
            last_name
            companyFunction{
                id
            }
            customCompanyFunction
        }
        certifications {
            id
            type
            domain {
                id
            }
        }
        custom_certifications
        plain_certifications
        references
        domains {
            id
            sector {
                id
            }
        }
        pictures {
            id
            image
            order
            is_main
        }
        files {
            id
            preview_url
            pdf_url
            title
        }
        location {
            name
            address
            address_2
            city
            zip_code
        }
        links {
            id
            type
            value
        }
    }
`

export const getCompany = (variables, headers) => query(gql`
        query getCompany {
            me {
                company {
                    ...companyFragment
                }
            }
        }
        ${companyFragment}
  `,
variables, headers)


export const updateCompany = (variables, headers) => query(gql`
    mutation updateCompanyInformation($id: ID!, $input: UpdateCompanyInput!) {
        updateCompanyInformation(id: $id, input: $input) {
            ...companyFragment
        }
    }
     ${companyFragment}
`, variables, headers)
