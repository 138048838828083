import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserNotifications'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  updateNotifications: requestInitialState(),
  updateNotificationsPreferences: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userNotifications: setData', (data) => ({ data })),
  setLoaded: createAction('userNotifications: setLoaded', (loaded) => ({ loaded })),
  //
  updateNotifications: createAction('userNotifications: updateNotifications', (values) => ({ values })),
  updateNotificationsSuccess: createAction('userNotifications: updateNotificationsSuccess', (data) => data),
  updateNotificationsError: createAction('userNotifications: updateNotificationsError', (errors) => Errors(errors)),
  //
  updateNotificationsPreferences: createAction('userNotifications: updateNotificationsPreferences', (values) => ({ values })),
  updateNotificationsPreferencesSuccess: createAction('userNotifications: updateNotificationsPreferencesSuccess', (data) => data),
  updateNotificationsPreferencesError: createAction('userNotifications: updateNotificationsPreferencesError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'updateNotifications'),
  ...requestReducers(actions, 'updateNotificationsPreferences'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const updateNotifications = (state) => root(state).updateNotifications
const updateNotificationsPreferences = (state) => root(state).updateNotificationsPreferences

export const selectors = {
  isLoaded,
  data,
  updateNotifications,
  updateNotificationsPreferences,
}
