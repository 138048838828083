import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import offerQueries from '../../graphql/query/Offer/index'
import { actions as AuthActions, selectors as AuthSelectors } from '../auth/redux'
import ApiSagas from '../api/sagas'

import { actions as OfferActions } from './redux'


export default class OfferSagas {

  static* updateSavedOffers({ payload }) {
    const me = yield select(AuthSelectors.user)

    const result = yield call(ApiSagas.query, offerQueries.accountOffers, { id: me.id, ...payload.values })

    if (result.errors && result.errors.length) {
      yield put(OfferActions.updateSavedOffersError(result.errors))
    } else {
      yield put(AuthActions.setUser({ ...me, ...result.data.accountOffers }))
      yield put(OfferActions.updateSavedOffersSuccess(result.data.accountOffers))
    }
  }

  static* applyToOffer({ payload }) {
    const me = yield select(AuthSelectors.user)

    const result = yield call(ApiSagas.query, offerQueries.applyToOffer, { ...payload.values })

    if (result.errors && result.errors.length) {
      yield put(OfferActions.applyToOfferError(result.errors))
    } else {
      yield put(AuthActions.setUser({ ...me, ...result.data.applyToOffer }))
      yield put(OfferActions.applyToOfferSuccess(result.data.applyToOffer))
    }
  }


  static* loop() {
    yield all([
      //
      takeLatest(OfferActions.updateSavedOffers.getType(), OfferSagas.updateSavedOffers),
      takeLatest(OfferActions.applyToOffer.getType(), OfferSagas.applyToOffer),
    ])
  }

}
