// eslint-disable-next-line
import React, { useEffect } from 'react'
import { cookiesTool } from 'react-gdpr'
import { useDispatch } from 'react-redux'

import { actions as TrackingActions } from '../../redux/tracking/redux'
import { getCookie } from '../../helpers/CookieHelpers'


function Tracker() {
  const dispatch = useDispatch()

  useEffect(() => {
    if (cookiesTool.isActive('analytics')) {
      dispatch(TrackingActions.enableTracking())
    } else if (cookiesTool.isActive('ads')) {
      dispatch(TrackingActions.ad(true))
    } else if (getCookie('gdpr-ads') === undefined) {
      dispatch(TrackingActions.ad('default'))
    }
  }, [dispatch])

  return null
}

export default Tracker
