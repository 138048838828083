import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function GDPRBanner(props) {
  const classes = useStyles(props)
  const {
    textProps,
    handleAccept,
    handleRefuse,
    handleCookieStatement,
    handleOpenSettings,
    handleKnowMore,
  } = props

  function renderDescription() {
    if (textProps.description) {
      const textPart1 = textProps.description.split(':cookie_statement') && textProps.description.split(':cookie_statement')[0] ? textProps.description.split(':cookie_statement')[0] : ''
      const textPart2 = textProps.description.split(':cookie_statement') && textProps.description.split(':cookie_statement')[1] ? textProps.description.split(':cookie_statement')[1].split(':cookie_preferences')[0] : ''
      const linkCookieStatement = (
        <span
          role="presentation"
          className={classes.span}
          onClick={handleCookieStatement}
          dangerouslySetInnerHTML={{ __html: textProps.politicLabel }}
        />
      )
      const linkCookiePreferences = (
        <span
          role="presentation"
          className={classes.span}
          onClick={handleOpenSettings}
          dangerouslySetInnerHTML={{ __html: textProps.preferencesLabel }}
        />
      )

      return (
        <p className={classes.description}>
          {textPart1}
          {linkCookieStatement}
          {textPart2}
          {linkCookiePreferences}
          {textPart2[0] ? textPart2[0] : ''}
        </p>
      )
    }

    return null
  }

  return (
    <div className={classes.container}>
      <h6
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: textProps.title }}
      />
      <div className={classes.wrapper}>
        <div className={classes.descriptionWrapper}>
          {renderDescription()}
        </div>
        <div className={classes.ctaWrapper}>
          <ActionButton
            className={classes.cta}
            label={textProps.acceptLabel}
            onClick={handleAccept}
            color="white"
            isOutlined
          />
          <ActionButton
            className={classes.cta}
            label={textProps.refuseLabel}
            onClick={handleRefuse}
            color="white"
            isOutlined
          />
          {handleKnowMore && textProps.knowMoreLabel && (
            <ActionButton
              className={classes.cta}
              label={textProps.knowMoreLabel}
              onClick={handleKnowMore}
              color="white"
              isOutlined
              iconRight
              icon={iconsKeys.ArrowRight}
              colorIconHover={colors.darkBlue}
            />
          )}
        </div>
      </div>
    </div>
  )
}

GDPRBanner.styles = styles

GDPRBanner.propTypes = {
  textProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    politicLabel: PropTypes.string,
    preferencesLabel: PropTypes.string,
    acceptLabel: PropTypes.string,
    refuseLabel: PropTypes.string,
    knowMoreLabel: PropTypes.string,
  }),
  handleAccept: PropTypes.func,
  handleCookieStatement: PropTypes.func,
  handleKnowMore: PropTypes.func,
  handleOpenSettings: PropTypes.func,
  handleRefuse: PropTypes.func,
}

GDPRBanner.defaultProps = {
  textProps: null,
  handleAccept: () => undefined,
  handleCookieStatement: () => undefined,
  handleKnowMore: () => undefined,
  handleOpenSettings: () => undefined,
  handleRefuse: () => undefined,
}

export default withMemo()(GDPRBanner)
