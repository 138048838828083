import gql from 'graphql-tag'


const DomainFragment = gql`
      fragment DomainFragment on Domain {
          id
          name
          slug
          bloc_image
          color
      }
  `

export default DomainFragment
