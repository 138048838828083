import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const postFragment = gql`
    fragment postFragment on Post {
        id
        title
        slug
        type
        description
        picture
        files {
            id
            preview_url
            pdf_url
            title
        }
        link
        link_label
        published_at
        is_published
        expires_at
    }
`

export const getPost = (variables, headers) => query(gql`
    query postBySlug($slug: String!) {
        post:postBySlug(slug: $slug) {
            ...postFragment
        }
    }
    ${postFragment}
  `,
variables, headers)


export const createPost = (variables, headers) => query(gql`
    mutation createPost(
        $input: CreatePostInput!
    ) {
        post:createPost(input: $input) {
            ...postFragment
        }
    }
    ${postFragment}
`, variables, headers)

export const updatePost = (variables, headers) => query(gql`
    mutation updatePost(
        $id: ID!
        $input: UpdatePostInput!
    ) {
        post:updatePost(id: $id, input: $input) {
            ...postFragment
        }
    }
    ${postFragment}
`, variables, headers)

export const publishPost = (variables, headers) => query(gql`
    mutation publishPost(
        $id: ID!
    ) {
        post:publishPost(id: $id) {
            ...postFragment
        }
    }
    ${postFragment}
`, variables, headers)
