import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'
import { actions as AppActions } from '../../redux/app/redux'
import PaymentQueries from '../../graphql/query/Payment'
import ApiSagas from '../../redux/api/sagas'

import { actions } from './redux'


export default class UserValidateSepaSagas {

  static* init(ctx) {
    yield put(showLoading())

    const query = ctx.query

    const result = yield call(ApiSagas.query, PaymentQueries.ValidateSepaMandate, {
      unique_mandate_reference: query.unique_mandate_reference,
      order_reference: query.REF ? query.REF : query.ORDER_REFERENCE,
      _ssi: query._ssi ? query._ssi : query.paymentId,
    })

    console.log('result', result)

    if (!result.errors && result.data.validateSepaMandate.status === 'waiting_for_reference') {
      yield put(actions.setIsPending(true))
    }

    if (!result.errors && result.data.validateSepaMandate.status === 'active') {
      yield put(actions.setIsValid(true))
    }

    const seo = yield call(UserValidateSepaSagas.transformSeo)

    yield all([
      yield put(AppActions.setSeo(seo)),
      yield put(actions.setLoaded(true)),
      yield put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const titleLabel = i18nStore[i18nLang].translation.SEO_user_validate_sepa_title
    const descriptionLabel = i18nStore[i18nLang].translation.SEO_user_validate_sepa_description

    const seoProps = {
      title: titleLabel,
      image: '',
      description: descriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userValidateSepa)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
    ])
  }

}
