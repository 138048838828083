import { desktop } from '../../theme'


export default {
  container: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    paddingTop: '6.4rem',
    ...desktop({
      paddingTop: '8rem',
    }),
  },
}
