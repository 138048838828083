import { colors, fonts, tablet, sizes } from '../../theme'


export default {
  container: {
    width: 'calc(100% - 4rem)',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: '2rem',
    top: 'auto',
    backgroundColor: colors.nightRider,
    padding: '3rem 2rem',
    zIndex: 12,
    borderRadius: '2rem',
    ...tablet({
      width: '100',
      padding: '3rem 5rem',
    }),
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: 0,
    ...tablet({
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  },
  title: {
    padding: 0,
    margin: 0,
    marginBottom: '1rem',
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: colors.pureWhite,
    textTransform: 'uppercase',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    ...tablet({
      paddingLeft: 0,
      paddingRight: 0,
    }),
  },
  descriptionWrapper: {
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    marginBottom: '2rem',
    ...tablet({
      marginBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    }),
  },
  description: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    color: colors.pureWhite,
  },
  span: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  ctaWrapper: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexFlow: 'row wrap',
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'left',
    justifyContent: 'flex-start',
    ...tablet({
      minWidth: '30rem',
      marginLeft: '6rem',
      marginRight: 0,
      alignSelf: 'center',
      justifyContent: 'center',
    }),
  },
  cta: {
    marginLeft: '.5rem',
    marginRight: '.5rem',
    marginBottom: '1rem',
  },
}
