import React from 'react'
import Head from 'next/head'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import HeaderWrapper from '../../components/Header/wrapper'
import FooterWrapper from '../../components/Footer/wrapper'
import NotFoundWrapper from '../../components/NotFound/wrapper'
import { Breadcrumb } from '../../components'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import usePWAInstallPrompt from '../../hooks/usePWAInstallPrompt'

import styles from './styles'


const useStyles = createUseStyles(styles)

function ErrorTemplate() {
  usePWAInstallPrompt()


  const { t } = useTranslation()
  const pageSelector = useSelector(AppSelectors.page)
  const homeLink = pageSelector(pageType.HOME)?.link
  const classes = useStyles()
  const breadcrumb = {
    entries: [
      {
        label: t('breadcrumb_home'),
        link: homeLink,
      }, {
        label: t('breadcrumb_error_404'),
      },
    ],
  }

  return (
    <div className={classes.container}>
      <Head>
        <title>{t('SEO_404_title')}</title>
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content={process.env.APP_URL}
        />
      </Head>
      <HeaderWrapper />
      <Breadcrumb
        {...breadcrumb}
        className={classes.breadcrumb}
      />
      <NotFoundWrapper />
      <FooterWrapper />
    </div>
  )
}

ErrorTemplate.styles = styles

ErrorTemplate.propTypes = {}

ErrorTemplate.defaultProps = {}

export default ErrorTemplate
