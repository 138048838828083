import React from 'react'

import ErrorTemplate from '../../templates/Error'
import withMemo from '../../decorators/WithMemo'


const Error = (props) => (
  <ErrorTemplate {...props} />
)

Error.getInitialProps = async(ctx, store) => null

Error.propTypes = {}

Error.defaultProps = {}


export default withMemo()(Error)
