import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'

import { actions, selectors } from './redux'
import { getNotificationPreferences, updateNotificationPreferences, getNotificationsPreferences, updateNotificationsPreferences } from './queries'


export default class UserNotificationsSagas {

  static* init() {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, getNotificationPreferences)

    const res = yield call(ApiSagas.query, getNotificationsPreferences)

    const userSelector = yield select(AuthSelectors.user)
    const seo = yield call(UserNotificationsSagas.transformSeo, { isCompany: userSelector.isCompany })

    if (!result.errors) {
      yield all([
        put(actions.setData({ ...result.data.me, ...res.data.me })),
        put(AppActions.setJsonld(null)),
        put(AppActions.setSeo(seo)),
        put(actions.setLoaded(true)),
        put(hideLoading()),
      ])
    }

    return {}
  }

  static* updateNotifications({ payload }) {
    const values = payload.values
    const me = yield select(AuthSelectors.user)
    const input = {
      newsletter: values.newsletter,
      offer_notification_email: values.offer_notification_email,
      notification_scheduling: values.notification_scheduling,
    }

    const result = yield call(ApiSagas.query, updateNotificationPreferences, { id: me.id, input })

    if (result.errors && result.errors.length) {
      yield put(actions.updateNotificationsError(result.errors))
    } else {
      yield put(actions.setData(result.data.updateNotificationsPreferences))
      yield put(actions.updateNotificationsSuccess(result.data.updateNotificationsPreferences))
    }
  }

  static* updateNotificationsPreferences({ payload }) {
    const values = payload.values
    const me = yield select(AuthSelectors.user)
    const data = yield select(selectors.data)

    const initialValues = [
      {
        type: 'new_offers',
        is_active: false,
        subtypes: [],
      },
      {
        type: 'new_companies',
        is_active: false,
        subtypes: [],
      },
      {
        type: 'new_posts',
        is_active: false,
        subtypes: [],
      },
      {
        type: 'new_events',
        is_active: false,
        subtypes: [],
      },
    ]

    const affectOldIdToNewValues = (old_, new_) => {
      old_.forEach((o) => {
        new_.forEach((n) => {
          if (o.type === n.type) {
            // eslint-disable-next-line no-param-reassign
            n.id = o.id
          }
        })
      })
    }

    affectOldIdToNewValues(data.notificationPreferences, values.notificationPreferences)

    const existingNotifType = []

    values.notificationPreferences.forEach((notifPref) => {
      existingNotifType.push(notifPref.type)
    })

    initialValues.forEach((init) => {
      if (!existingNotifType.includes(init.type)) {
        values.notificationPreferences.push({ type: init.type, is_active: false, subtypes: [] })
      }
    })

    const newNotificationPreferences = values.notificationPreferences.map((notifPref) => ({
      type: notifPref.type,
      is_active: notifPref.is_active,
      subtypes: notifPref.subtypes,
    }))

    affectOldIdToNewValues(data.notificationPreferences, newNotificationPreferences)

    const createNewNotificationPreferences = []

    newNotificationPreferences.forEach(
      (newNotifPref) => newNotifPref.id === undefined && createNewNotificationPreferences.push(newNotifPref)
    )

    const updateNewNotificationPreferences = []

    newNotificationPreferences.forEach(
      (newNotifPref) => newNotifPref.id !== undefined && updateNewNotificationPreferences.push(newNotifPref)
    )

    const updateNewNotificationPreferencesWithoutUnwantedProperty = []

    updateNewNotificationPreferences.forEach(
      (updatedNotif) => updateNewNotificationPreferencesWithoutUnwantedProperty.push(
        { id: updatedNotif.id,
          type: updatedNotif.type,
          is_active: updatedNotif.is_active,
          subtypes: updatedNotif.subtypes,
        }
      )
    )

    const notificationPreferences = {
      create: createNewNotificationPreferences,
      update: updateNewNotificationPreferencesWithoutUnwantedProperty,
    }

    const result = yield call(ApiSagas.query, updateNotificationsPreferences,
      { id: me.id, notificationPreferences })

    if (result.errors && result.errors.length) {
      yield put(actions.updateNotificationsPreferencesError(result.errors))
    } else {
      yield put(actions.setData({ ...data, ...result.data.updateNotificationsPreferences }))
      yield put(actions.updateNotificationsPreferencesSuccess(
        { ...data, ...result.data.updateNotificationsPreferences }
      ))
    }
  }



  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const candidateTitleLabel = i18nStore[i18nLang].translation.SEO_candidate_notifications_title
      const candidateDescriptionLabel = i18nStore[i18nLang].translation.SEO_candidate_notifications_description
      const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_notifications_title
      const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_notifications_description
      const isCompany = data.isCompany

      const seoProps = {
        title: isCompany ? companyTitleLabel : candidateTitleLabel,
        image: '',
        description: isCompany ? companyDescriptionLabel : candidateDescriptionLabel,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userNotifications)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(actions.updateNotifications.getType(), UserNotificationsSagas.updateNotifications),
      takeLatest(actions.updateNotificationsPreferences.getType(), UserNotificationsSagas.updateNotificationsPreferences),
    ])
  }

}
