import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const profileFragment = gql`
    fragment profileFragment on User{
        companyFunction {
            id
            name
        }
        company_function_description
        avatar
        phone
        phone_professional
        domains {
            id
            name
        }
        links {
            id
            type
            value
        }
        current_needs
    }
`

export const getProfile = (variables, headers) => query(gql`
    query GetProfile {
      me {
          ...profileFragment
      }
    }
        ${profileFragment}
  `,
variables, headers)


export const updateProfile = (variables, headers) => query(gql`
    mutation updatePersonalInformation(
        $id: ID!,
        $input: UpdatePersonalInformationInput!
    ) {
        updatePersonalInformation(id: $id, input: $input) {
            ...profileFragment
        }
    }
        ${profileFragment}
`, variables, headers)
