/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import articleQueries from '../../graphql/query/Article/index'
import { assetUrl, routeParamSlug } from '../../helpers/UrlHelpers'
import { actions as AppActions, pageType, resourceType, selectors as AppSelectors } from '../../redux/app/redux'
import { articleJsonLd } from '../../helpers/JsonLdHelpers'
import Share from '../../data/models/Share'
import routes, { Router } from '../../routes'
import app from '../../config/app'

import { actions as ArticleDetailActions } from './redux'


export default class ArticleDetailsSagas {

  static* init({ asPath, query, res }) {
    return yield call(ArticleDetailsSagas.loadPost, query, asPath, res)
  }

  static* loadPost({ slug = '/' }) {
    yield put(showLoading())
    const errors = []

    const result = yield call(ApiSagas.query, articleQueries.articleBySlug, { slug })

    // Check if errors
    if (result.errors && result.errors.length) {
      result.errors.map((error) => errors.push(error))
    }

    // Check errors
    if (errors && errors.length >= 1) {
      console.log('[ARTICLE] 404 error loading data', errors)
      yield put(hideLoading())
      return { error: 404 }
    }

    const returnedData = result.data?.articleBySlug

    if (returnedData === null) {
      console.log('[ARTICLE] 404 error loading data', result)
      yield put(hideLoading())
      return { error: 404 }
    }

    const breadCrumb = yield call(ArticleDetailsSagas.transformBreadcrumb, returnedData)
    const jsonld = yield call(ArticleDetailsSagas.transformJsonld, returnedData)
    const seo = yield call(ArticleDetailsSagas.transformSeo, returnedData)

    yield all([
      put(ArticleDetailActions.setData(returnedData)),
      put(AppActions.setBreadcrumb(breadCrumb)),
      put(AppActions.setResource(resourceType.POST, returnedData?.slug)),
      put(AppActions.setJsonld(jsonld)),
      put(AppActions.setSeo(seo)),
    ])

    yield all([
      put(ArticleDetailActions.setLoaded(true)),
      put(hideLoading()),
    ])
  }

  static* transformBreadcrumb(data) {
    if (data) {
      const pages = yield select(AppSelectors.pages)

      return [
        {
          label: pages[pageType.HOME].title,
          title: pages[pageType.HOME].title,
          link: pages[pageType.HOME].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.HOME].link) },
        },
        {
          label: pages[pageType.ARTICLES].title,
          title: pages[pageType.ARTICLES].title,
          link: pages[pageType.ARTICLES].link,
          route: routes.page.name,
          routeParams: { slug: routeParamSlug(pages[pageType.POSTS].link) },
        },
        {
          label: data.title,
          title: data.title,
        },
      ]
    }

    return []
  }

  static transformJsonld(data) {
    if (data) {
      return articleJsonLd(data)
    }

    return {}
  }

  static transformSeo(data) {
    if (data && data.share) {
      const transformedShare = Share(data.share)

      return {
        ...transformedShare,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.post, { slug: data.slug })}`,
        image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
        noindex: false,
      }
    }

    return {}
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
