import PropTypes from 'prop-types'

import Domain from './Domain'


const Certification = (props) => (props && {
  id: props.id,
  name: props.name,
  logo: props?.logo,
  type: props?.type,
  domain: Domain(props?.domain),
})

Certification.propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  type: PropTypes.string,
  domain: Domain.propTypes,
})

export default Certification
