import cx from 'classnames'
import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)


function ImpersonateBanner(props) {
  const classes = useStyles(props)
  const { className } = props

  return (
    <div
      className={cx(classes.container, className)}
    >
      <p className={cx(classes.text, className)}>Vous êtes connecté en prise de contrôle</p>
    </div>
  )
}

ImpersonateBanner.propTypes = {
  className: PropTypes.string,
}

ImpersonateBanner.defaultProps = {
  className: null,
}

export default withMemo()(ImpersonateBanner)
