import { call } from 'redux-saga/effects'

import GraphApi from '../../graphql/GraphApi'
import { query } from '../../helpers/GraphqlHelpers'

import * as queries from './queries'
import transformers from './transformers'


export default class Services {

  static* loadInitialData(variables) {
    return yield call(GraphApi.query, {
      query: (v, headers = {}) => query(queries.initialData, v, headers),
      transformer: transformers.initialData,
      variables,
    })
  }

  static* loadArticleCategories(variables) {
    return yield call(GraphApi.query, {
      query: (v, headers = {}) => query(queries.articleCategories, v, headers),
      transformer: transformers.transformArticleCategories,
      variables,
    })
  }

}
