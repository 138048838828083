/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import OfferCard from '../../data/models/OfferCard'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as authActions, selectors as authSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { deleteCandidacy, getOffer } from './queries'
import { actions } from './redux'


export default class UserCandidacySagas {

  static* init(ctx) {
    yield put(showLoading())

    const { query } = ctx

    if (query.id) {
      const user = yield select(authSelectors.user)
      const hasApplied = (user?.candidacies?.find((c) => c?.offer?.reference === query.id))

      if (!hasApplied) {
        yield put(hideLoading())
        return { error: 404 }
      }

      const offerResult = yield call(ApiSagas.query, getOffer, { reference: query.id })

      if (offerResult.errors || !offerResult.data.offer) {
        yield put(hideLoading())
        return { error: 404 }
      }

      const currentOffer = OfferCard(offerResult.data.offer)

      yield put(actions.setData(currentOffer))
    }

    const seo = yield call(UserCandidacySagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_title
    const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPublishOffer)}`,
      noindex: true,
    }

    return seoProps
  }

  static* deleteCandidacy({ payload }) {
    const result = yield call(ApiSagas.query, deleteCandidacy, { id: payload })

    if (result.errors) {
      console.log('UserCandidacy: Errors', result.errors)
      yield put(actions.deleteCandidacyError(result.errors))
    } else {
      const user = yield select(authSelectors.user)
      const offers = Array.from(user?.offers)

      const index = offers.findIndex((o) => o.id === payload)

      offers.splice(index, 1)

      yield put(authActions.setUser({ ...user, company: { ...user?.company, offers } }))

      Router.replaceRoute(routes.userCandidacies.name)
    }
  }

  static* loop() {
    yield all([
      takeLatest(actions.deleteCandidacy.getType(), UserCandidacySagas.deleteCandidacy),
    ])
  }

}
