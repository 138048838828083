import gql from 'graphql-tag'

import { MenuableItemFragment } from './MenuableItemFragment'


export const MenuDataFragment = {
  fragment: gql`
      fragment menuData on Menu {
          id
          label
          type
          link
          target
          location
          order
          ...menuableItem
      }
      ${MenuableItemFragment.fragment}
  `,
}
