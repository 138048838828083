import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'UserPublishPost'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  submitPost: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userPublishPost: setData', (data) => ({ data })),
  setLoaded: createAction('userPublishPost: setLoaded', (loaded) => ({ loaded })),
  //
  submitPost: createAction('userPublishPost: submitPost', (values) => ({ values })),
  submitPostError: createAction('userPublishPost: submitPostError', (errors) => Errors(errors)),
  submitPostSuccess: createAction('userPublishPost: submitPostSuccess', (data) => ({ data })),
  submitPostReset: createAction('userPublishPost: submitPostReset'),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'submitPost'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const submitPost = (state) => root(state).submitPost

export const selectors = {
  isLoaded,
  data,
  submitPost,
}
