/* eslint-disable max-lines */
import moment from 'moment'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import FileEntry from '../../data/models/FileEntry'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as authActions, selectors as authSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import i18n from '../../i18n'

import { createOffer, getOffer, updateOffer } from './queries'
import { actions, selectors } from './redux'


const getFiles = (source, values) => {
  const sourceByKey = (source && source.files
    && source.files.reduce(
      (r, { id, ...value }) => ({
        ...r,
        [id]: { id, ...value },
      }),
      {}
    ))
    || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceByKey[value.id]

    if (value.id && src) {
      if (value.title !== src.title) {
        update.push({ id: src.id, title: value.title })
      }
    } else if (value.key && !src) {
      create.push({ path: value.key, title: value.title })
    }
  }
  const valuesIds = values.files.map((entry) => entry.id)
  const _delete = !source ? [] : source.files.filter((entry) => !valuesIds.includes(entry.id)).map((entry) => entry.id)

  values.files.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}


export default class UserPublishOfferSagas {

  static* init(ctx) {
    yield put(showLoading())

    const { query } = ctx

    if (query.id) {
      const offerResult = yield call(ApiSagas.query, getOffer, { reference: query.id })

      if (offerResult.errors || !offerResult.data.offer) {
        yield put(hideLoading())
        return { error: 404 }
      }

      const currentOffer = offerResult.data.offer

      const offerData = {
        ...(!query.duplicate && { id: currentOffer.id }),
        label: currentOffer.label,
        date: currentOffer.maximal_execution_at,
        introduction: currentOffer.introduction,
        description: currentOffer.description,
        budget: currentOffer.budget,
        type: currentOffer.type,
        files: currentOffer.files.map(FileEntry),
        city: currentOffer.city,
        categories: currentOffer.categories?.map((c) => c.id),
        keywords: currentOffer.keywords,
        link: currentOffer?.link || '',
        company_name: currentOffer?.company_name || '',
      }

      yield put(actions.setData(offerData))
    } else {
      yield put(actions.setData(null))
    }

    const seo = yield call(UserPublishOfferSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  // eslint-disable-next-line complexity
  static* submitOffer({ payload }) {
    const values = payload.values

    const data = yield select(selectors.data)

    const t = (str) => i18n.t(str)

    const input = {
      label: values.label,
      maximal_execution_at: values.date && moment(values.date).format('YYYY-MM-DD'),
      introduction: values.introduction,
      description: values.description,
      budget: values.budget,
      type: values.type,
      files: getFiles(data, values),
      city: values.city,
      categories: values.categories ? {
        sync: values.categories,
      } : null,
      keywords: values.keywords,
    }

    let result = null

    // This condition is maybe not required, it seems to be forgotten in previous deploy
    if (!moment(values.date).isValid()) {
      result = {
        errors: [{
          extensions: {
            category: 'validation',
            validation: {
              maximal_execution_at: [t('offer_form_maximal_execution_at_required')],
            },
          },
          message: 'The given data was invalid',
        }],
      }
    } else if (data?.id) {
      result = yield call(ApiSagas.query, updateOffer, { id: data.id, input })
    } else {
      result = yield call(ApiSagas.query, createOffer, { input })
    }

    if (result.errors) {
      console.log('UserPublishOffer: Errors', result.errors)
      yield put(actions.submitOfferError(result.errors))
    } else {
      const reference = result.data.offer.reference
      const user = yield select(authSelectors.user)
      const offers = Array.from(user?.offers)
      const offer = result.data.offer

      if (data?.id) {
        const index = offers.find((o) => o.id === data.id)

        offers[index] = offer
      } else {
        offers.push(offer)
      }
      yield put(authActions.setUser({ ...user, company: { ...user?.company, offers } }))

      Router.replaceRoute(routes.userOffer.name, { id: reference })

      //  yield call(redirect, null, offersLink, 201)
    }
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_title
    const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPublishOffer)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      takeLatest(actions.submitOffer.getType(), UserPublishOfferSagas.submitOffer),
    ])
  }

}
