import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'userDashboard'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,
  markNotificationAsRead: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('userDashboard: setSlug', (slug) => ({ slug })),
  setData: createAction('userDashboard: setData', (data) => ({ data })),
  setLoaded: createAction('userDashboard: setLoaded', (loaded) => ({ loaded })),
  //
  markNotificationAsRead: createAction('userDashboard: markNotificationAsRead', (values) => ({ values })),
  markNotificationAsReadSuccess: createAction('userDashboard: markNotificationAsReadSuccess'),
  markNotificationAsReadError: createAction('userDashboard: markNotificationAsReadError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'markNotificationAsRead'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const markNotificationAsRead = (state) => root(state).markNotificationAsRead

export const selectors = {
  isLoaded,
  slug,
  data,
  markNotificationAsRead,
}
