import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserLocations'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  updateLocations: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userLocations: setData', (data) => ({ data })),
  setLoaded: createAction('userLocations: setLoaded', (loaded) => ({ loaded })),
  //
  updateLocations: createAction('userLocations: updateLocations', (values) => ({ values })),
  updateLocationsSuccess: createAction('userLocations: updateLocationsSuccess', (data) => data),
  updateLocationsError: createAction('userLocations: updateLocationsError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'updateLocations'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const updateLocations = (state) => root(state).updateLocations

export const selectors = {
  isLoaded,
  data,
  updateLocations,
}
