/* eslint-disable camelcase */
import { cookiesTool } from 'react-gdpr'

import { pushToDataLayer } from '../../helpers/GTMHelpers'

import { actions } from './redux'


const isGAIDExist = typeof process.env.GA_ID === 'string' && process.env.GA_ID.length > 0

const trackPage = (store, url) => {
  const codeEmbeded = store.getState().tracking.codeEmbeded

  if (!codeEmbeded || !cookiesTool.isActive('analytics') || !isGAIDExist) {
    return
  }

  window.gtag('config', process.env.GA_ID, {
    page_path: url,
  })

  if (process.env.GW_ID) {
    window.gtag('config', process.env.GW_ID, {
      page_path: url,
    })
  }
}

const trackEvent = (store, payload) => {
  const codeEmbeded = store.getState().tracking.codeEmbeded

  if (!codeEmbeded || !cookiesTool.isActive('analytics') || !isGAIDExist) {
    return
  }
  const { category: event_category, action, label: event_label, value } = payload

  // Send event
  window.gtag('event', action, {
    event_category,
    event_label,
    value,
  })
}

const tracking = (store, payload) => {
  const codeEmbeded = store.getState().tracking.codeEmbeded

  if (codeEmbeded) {
    return
  }

  pushToDataLayer({
    event: 'cookieAccepted',
  })

  store.dispatch(actions.setCodeEmbeded())
}


const ad = (store, value) => {
  if (value === 'default') {
    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    })
    return
  }

  if (value) {
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    })
  } else {
    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    })
  }
}


const TrackingMiddleware = (store) => (next) => (action) => {
  const actionMap = {
    [actions.trackPage]: trackPage,
    [actions.trackEvent]: trackEvent,
    [actions.enableTracking]: tracking,
    [actions.ad]: ad,
  }

  if (actionMap[action.type] !== undefined) {
    actionMap[action.type](store, action.payload)
  }

  next(action)
}

export default TrackingMiddleware
