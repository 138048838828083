import { cookiesTool } from 'react-gdpr'
import { all, call, put, take } from 'redux-saga/effects'

import QueryString from '../../helpers/QueryString'
import routes, { Router } from '../../routes'
import ApiSagas from '../api/sagas'
import AuthSagas from '../auth/sagas'
import TranslationSagas from '../i18n/sagas'

import { actions } from './redux'
import Services from './services'


const queryStringConfig = { arrayFormat: 'comma' }

export default class AppSagas {

  static* init(ctx, pageSaga) {
    const { pathname, query, asPath, req } = ctx

    yield call(AppSagas.updateRoute, {
      pathname,
      query,
      asPath,
    })

    if (req) {
      yield call(AppSagas.setPreview, req.IS_PREVIEW)
    }

    yield call(AppSagas.appStart)
    yield call(AuthSagas.init, ctx)

    if (!process.browser) {
      yield call(AppSagas.loadInitialData)
      yield call(TranslationSagas.init, query.lang || 'fr')
    }

    // Wait until authSaga is inited, and menu is loaded then
    const redirect = yield call(AuthSagas.redirectLogged, ctx)

    if (redirect) {
      yield take(actions.setRoute.getType())
      return
    }


    // Fix Page param /p1 => ''
    const match = ctx.query.slug && ctx.query.slug.match(/\/p([0-9]+)/)

    if (!ctx.query.page && match && ctx.pathname === '/Page') {
      ctx.query.slug = ctx.query.slug.replace(/\/p([0-9]+)/, '')
      ctx.query.page = match[1]
    }

    if (ctx.query.page && (ctx.query.page === '1' || ctx.query.page === '0')) {
      // Add get params if exists
      const params = { ...ctx.query }

      Object.keys(params).forEach((key) => (key === 'slug' || key === 'page') && delete params[key])
      const qs = QueryString.stringify(params, queryStringConfig)
      const route = Router.getRouteUrl(routes.page, { slug: ctx.query.slug })

      return redirect(ctx.res, `${route}${qs ? `?${qs}` : ''}`)
    }

    // clear JsonLd
    yield put(actions.setJsonld(null))

    const initialProps = pageSaga ? yield call(pageSaga, ctx, ctx.store) : {}

    if (ctx.res && ctx.res.statusCode > 400) {
      console.log('AppSagas: Error', ctx.res.statusCode, ctx.res.statusMessage, {
        asPath: ctx.asPath,
        query: ctx.query,
        pathname: ctx.pathname,
        err: ctx.err,
      })
      ctx.res.statusCode = 404
    }

    if (ctx.res && initialProps && initialProps.error) {
      ctx.res.statusCode = initialProps.error
    }

    return initialProps || { loaded: true }
  }

  static* setPreview(preview) {
    const isPreview = (parseInt(preview, 10) === 1)

    yield put(actions.setPreview(isPreview))
  }

  static* appStart() {
    yield all([
      call(ApiSagas.initHeaders),
      call(AppSagas.initTracking),
    ])
  }

  static initTracking() {
    if (cookiesTool.isActive('cookiesEdited') && !cookiesTool.isActive('analytics')) {
      cookiesTool.setActive('analytics', false)
    }
  }

  static* loadInitialData() {
    const result = yield call(Services.loadInitialData)
    const articleCategories = yield call(Services.loadArticleCategories)

    if (result.transformed) {
      const { menus, pages, sectors, certifications, companyFunctions, categories } = result.transformed

      yield all([
        put(actions.setMenus(menus)),
        put(actions.setPages(pages)),
        put(actions.setSectors(sectors)),
        put(actions.setCertifications(certifications)),
        put(actions.setCompanyFunctions(companyFunctions)),
        put(actions.setCategories(categories)),
      ])
    } else {
      console.log('loadInitialData error', result)
    }

    if (articleCategories.transformed) {
      yield all([
        put(actions.setArticleCategories(articleCategories.transformed)),
      ])
    } else {
      console.log('loadArticleCategories error', articleCategories)
    }
  }

  static* updateRoute(route) {
    yield put(actions.setRoute(route))
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
