import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'

import AdminPanel from '../../components/AdminPanel'
import GDPRBannerWrapper from '../../components/GDPRBanner/wrapper'
import GDPRSettingsPanel from '../../components/GDPRSettingsPanel'
import LoadingBar from '../../components/LoadingBar'
import Seo from '../../components/Seo'
import app from '../../config/app'
import withMemo from '../../decorators/WithMemo'
import ErrorView from '../../pages/Error'
import LegacyBrowser from '../../components/LegacyBrowser'
import useLegacyBrowser from '../../hooks/useLegacyBrowser'
import { actions as AppActions, selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import { actions as TrackingActions } from '../../redux/tracking/redux'
import { Router } from '../../routes'
import Tracker from '../Tracker'
import ImpersonateBanner from '../../components/ImpersonateBanner'
import Jsonld from '../../components/JsonLd'


import styles from './styles'


const useStyles = createUseStyles(styles)

const AppGate = (props) => {
  // eslint-disable-next-line react/prop-types
  const { Component, pageContext, ...pageProps } = props

  const preview = useSelector(AppSelectors.preview)
  const seo = useSelector(AppSelectors.seo)
  const jsonld = useSelector(AppSelectors.jsonld)

  const token = useSelector(AuthSelectors.token)

  const isError = pageProps.error
  const isErrorComp = pageProps.errorComponent

  const classes = useStyles(props)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AppActions.mount())
  }, [dispatch])

  useEffect(() => {
    Router.onRouteChangeComplete = (url) => {
      dispatch(TrackingActions.trackPage(url))
      dispatch(AppActions.scrollTop(url))
    }
    return () => {
      Router.onRouteChangeComplete = null
    }
  }, [dispatch])

  const isLegacy = useLegacyBrowser()

  return (
    <div className={classes.wrapper}>
      {seo && (
        <Seo
          {...seo}
          base={preview === true ? app.APP_PREVIEW_URL : app.APP_URL}
        />
      )}
      {jsonld && <Jsonld data={jsonld} />}

      {isLegacy && (
        <LegacyBrowser />
      )}

      <LoadingBar />
      <Tracker />
      <GDPRSettingsPanel />
      <GDPRBannerWrapper />

      {token?.impersonate && (
        <ImpersonateBanner />
      )}

      {(isError && !isErrorComp)
        ? (
          <ErrorView
            pageContext={pageContext}
            {...pageProps}
          />
        )
        : (!!Component) && (
          <>
            <Component
              pageContext={pageContext}
              {...pageProps}
            />
          </>
        )}

      <AdminPanel />

    </div>
  )
}

AppGate.propTypes = {
  pageProps: PropTypes.shape({
    error: PropTypes.number,
    errorComponent: PropTypes.any,
  }),
}

AppGate.defaultProps = {
  pageProps: {},
}

export default withMemo()(AppGate)
