import gql from 'graphql-tag'


export const Labels = gql`    
    query Labels($localeFilters: LocaleFilters!) {
        labels(localeFilters: $localeFilters) {
            key
            value
            type
        }
    }
`
