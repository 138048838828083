import { all, fork } from 'redux-saga/effects'


const entities = [
  // Pages
  require('../pages/Article/sagas').default,
  require('../pages/Company/sagas').default,
  require('../pages/EmailUserNotifications/sagas').default,
  require('../pages/EmailVerification/sagas').default,
  require('../pages/Event/sagas').default,
  require('../pages/Offer/sagas').default,
  require('../pages/Page/sagas').default,
  require('../pages/Post/sagas').default,
  require('../pages/UserAccount/sagas').default,
  require('../pages/UserCandidacies/sagas').default,
  require('../pages/UserCandidacy/sagas').default,
  require('../pages/UserCompany/sagas').default,
  require('../pages/UserDashboard/sagas').default,
  require('../pages/UserLocations/sagas').default,
  require('../pages/UserNotifications/sagas').default,
  require('../pages/UserOffer/sagas').default,
  require('../pages/UserProfile/sagas').default,
  require('../pages/UserPublications/sagas').default,
  require('../pages/UserPublishOffer/sagas').default,
  require('../pages/UserPublishPost/sagas').default,
  require('../pages/UserRegisterPremium/sagas').default,
  require('../pages/UserRegisterWelcome/sagas').default,
  require('../pages/UserMessages/sagas').default,
  require('../pages/UserValidateSepa/sagas').default,
  require('../pages/UserPayment/sagas').default,
  require('../pages/UserResources/sagas').default,
  require('../pages/UserEvents/sagas').default,
  require('../pages/UserEvent/sagas').default,
  require('../pages/UserFavorite/sagas').default,

  // Global
  require('./api/sagas').default,
  require('./app/sagas').default,
  require('./auth/sagas').default,
  require('./chat/sagas').default,
  require('./i18n/sagas').default,
  require('./offer/sagas').default,
  require('./linkedin/sagas').default,
]


function* loop() {
  yield all(entities.map((entity) => entity.loop()))
}

export default function* rootSaga() {
  yield fork(loop)
}
