import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

// import Errors from '../../data/models/Errors'
// import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserValidateSepa'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  isValid: false,
  isPending: false,
}

//
// Actions
//
export const actions = {
  setData: createAction('userValidateSepa: setData', (data) => ({ data })),
  setLoaded: createAction('userValidateSepa: setLoaded', (loaded) => ({ loaded })),
  setIsValid: createAction('userValidateSepa: setIsValid', (isValid) => ({ isValid })),
  setIsPending: createAction('userValidateSepa: setIsPending', (isPending) => ({ isPending })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  [actions.setIsValid]: (state, { isValid }) => Immutable.static.merge(state, { isValid }),
  [actions.setIsPending]: (state, { isPending }) => Immutable.static.merge(state, { isPending }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const isValid = (state) => root(state).isValid
const isPending = (state) => root(state).isPending

export const selectors = {
  isLoaded,
  data,
  isValid,
  isPending,
}
