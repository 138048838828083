import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const offerFragment = gql`
    fragment userOfferCandidacyFragment on Offer {
        id
        reference
        label
        maximal_execution_at
        city
        published_at
        myCandidacy {
            id
            status
            message
            applied_at
            files {
                id
                preview_url
                pdf_url
                title
            }
        }
        categories {
            id
            name
            color
        }
    }
`

export const getOffer = (variables, headers) => query(gql`
    query offerByReference($reference: String!) {
        offer:offerByReference(reference: $reference) {
            ...userOfferCandidacyFragment
        }
    }
    ${offerFragment}
  `,
variables, headers)

export const deleteCandidacy = (variables, headers) => query(gql`
    mutation deleteCandidacy($id: ID!) {
        deleteCandidacy(id: $id) {
            id
        }
    }
  `,
variables, headers)
