/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'

import app from '../../config/app'
import FileEntry from '../../data/models/FileEntry'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as authSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import { setLocalStorageValue } from '../../utils/ReactUtils'
import { isValidDate } from '../../helpers/DateHelpers'

import { createPost, getPost, updatePost, publishPost } from './queries'
import { actions, selectors } from './redux'


const getFiles = (source, values) => {
  const sourceByKey = (source && source.files
    && source.files.reduce(
      (r, { id, ...value }) => ({
        ...r,
        [id]: { id, ...value },
      }),
      {}
    ))
    || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceByKey[value.id]

    if (value.id && src) {
      if (value.title !== src.title) {
        update.push({
          id: src.id,
          title: value.title,
        })
      }
    } else if (value.key && !src) {
      create.push({
        path: value.key,
        title: value.title,
      })
    }
  }
  const valuesIds = values.files.map((entry) => entry.id)
  const _delete = !source ? [] : source.files.filter((entry) => !valuesIds.includes(entry.id))
    .map((entry) => entry.id)

  values.files.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}

export default class UserPublishPostSagas {

  static* init(ctx) {
    yield put(showLoading())

    const isProfileComplete = yield select(authSelectors.isProfileComplete)

    if (!isProfileComplete) {
      setLocalStorageValue(app.COMPLETE_PROFILE_ATTEMPT, true)
      Router.replaceRoute(routes.userProfile.name)

      return
    }

    const { query } = ctx

    yield put(actions.submitPostReset())

    if (query.slug) {
      const postResult = yield call(ApiSagas.query, getPost, { slug: query.slug })

      if (postResult.errors || !postResult.data.post) {
        yield put(hideLoading())
        return { error: 404 }
      }

      const currentPost = postResult.data.post

      const postData = {
        id: currentPost.id,
        slug: currentPost.slug,
        title: currentPost.title,
        type: currentPost.type,
        image: currentPost.picture && {
          id: currentPost.picture,
          path: currentPost.picture,
        },
        files: currentPost.files.map(FileEntry),
        description: currentPost.description ?? '',
        link_url: currentPost.link ?? '',
        link_name: currentPost.link_label ?? '',
        is_published: currentPost.is_published ?? false,
        expires_at: currentPost.expires_at ? moment(currentPost.expires_at).toDate() : null,
      }

      yield put(actions.setData(postData))
    } else {
      yield put(actions.setData(null))
    }

    const seo = yield call(UserPublishPostSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* submitPost({ payload }) {
    const values = payload.values

    const data = yield select(selectors.data)

    const expiresAt = values?.expires_at ? moment(values.expires_at).format('YYYY-MM-DD HH:mm:ss') : null

    const input = {
      title: values.title,
      type: values.type,
      picture: values.image?.id && values.image.id !== data?.image?.id ? values.image.id : undefined,
      files: getFiles(data, values),
      description: values.description,
      link: values.link_url,
      link_label: values.link_name,
      is_published: values.is_published ?? true,
      expires_at: isValidDate(expiresAt) ? expiresAt : null,
    }

    let result = null

    if (data?.id) {
      if (values.is_published && !data.is_published) {
        yield call(ApiSagas.query, publishPost, {
          id: data.id,
        })
      }

      result = yield call(ApiSagas.query, updatePost, {
        id: data.id,
        input,
      })
    } else {
      result = yield call(ApiSagas.query, createPost, { input })
    }

    if (result.errors) {
      console.log('UserPublishPost: Errors', result.errors)
      yield put(actions.submitPostError(result.errors))
    } else {
      Router.replaceRoute(routes.userPublications.name)
    }
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_new_post_title
    const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_new_post_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPublishPost)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      takeLatest(actions.submitPost.getType(), UserPublishPostSagas.submitPost),
    ])
  }

}
