const entities = [
  // Externals
  {
    key: 'loadingBar',
    reducer: require('react-redux-loading-bar').loadingBarReducer,
  },

  // Pages
  require('../pages/Article/redux'),
  require('../pages/Company/redux'),
  require('../pages/EmailUserNotifications/redux'),
  require('../pages/EmailVerification/redux'),
  require('../pages/Event/redux'),
  require('../pages/Offer/redux'),
  require('../pages/Page/redux'),
  require('../pages/Post/redux'),
  require('../pages/Profile/redux'),
  require('../pages/UserAccount/redux'),
  require('../pages/UserCandidacies/redux'),
  require('../pages/UserCandidacy/redux'),
  require('../pages/UserCompany/redux'),
  require('../pages/UserDashboard/redux'),
  require('../pages/UserLocations/redux'),
  require('../pages/UserNotifications/redux'),
  require('../pages/UserOffer/redux'),
  require('../pages/UserProfile/redux'),
  require('../pages/UserPublications/redux'),
  require('../pages/UserPublishOffer/redux'),
  require('../pages/UserPublishPost/redux'),
  require('../pages/UserRegisterPremium/redux'),
  require('../pages/UserMessages/redux'),
  require('../pages/UserValidateSepa/redux'),
  require('../pages/UserPayment/redux'),
  require('../pages/UserResources/redux'),
  require('../pages/UserEvents/redux'),
  require('../pages/UserEvent/redux'),
  require('../pages/UserFavorite/redux'),

  // Global
  require('./api/redux'),
  require('./app/redux'),
  require('./auth/redux'),
  require('./chat/redux'),
  require('./i18n/redux'),
  require('./linkedin/redux'),
  require('./offer/redux'),
  require('./tracking/redux'),
]

// Make sure all entities have a defined key
const reducers = entities.reduce((acc, entity) => {
  if (!entity.key) {
    console.error('Missing entity key')
  }
  acc[entity.key] = entity.reducer
  return acc
}, {})

export default reducers
