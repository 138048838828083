import gql from 'graphql-tag'

import { ShareFragment } from '../Fragments/ShareFragment'


export const EventByFullSlug = gql`
    query eventByFullSlug($slug: String!) {
        eventByFullSlug(slug: $slug) {
            id
            linked_events {
                id
                name
                slug
                link
                type
                provider
                begin_at
                end_at
                full_slug
                has_page
            }
            name
            slug
            type
            provider
            place
            begin_at
            end_at
            link
            has_page
            background_image
            is_free_for_premium
            phone
            cost
            second_cost
            introduction
            description
            program
            location {
                name
                address
                address_2
                city
                zip_code
                image
                lat
                long
            }
            share {
                ...share
            }
        }
    }
    
    ${ShareFragment.fragment}
`
