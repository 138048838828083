import gql from 'graphql-tag'

import { MenuDataFragment } from '../../graphql/query/Fragments/MenuDataFragment'
import DomainFragment from '../../graphql/query/Fragments/DomainFragment'

import { pageType } from './redux'


const pageLinks = Object.values(pageType).map((type) => (`${type}: pageByFlexibleContent(type:${type}){full_slug, slug, title},`)).join('')

export const initialData = gql`
    query initialData {
        menus(orderBy: [{field: "order", order: ASC}]) {
            ...menuData
            parent {
                ...menuData
            }
            children {
                ...menuData
            }
        },
        ${pageLinks}
        companyFunctions {
            id
            name
        }
        sectors {
            id
            name
            slug
            domains {
                ... DomainFragment
            }
        }
        certifications {
            id
            name
            logo
            type
            domain {
                ... DomainFragment
            }
        }
        categories {
            id
            name
            slug
            color
            offerType
        }
    }
    # Fragments
    ${MenuDataFragment.fragment}
    ${DomainFragment}
`

export const articleCategories = gql`
    query articleCategories {
        articleCategories {
            id
            name
            theme
            bgColor
        }
    }
`
