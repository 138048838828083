import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import company from '../../graphql/query/Company'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import { getDiffQuery } from '../../helpers/GraphqlHelpers'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'

import { actions, selectors } from './redux'


export default class UserLocationsSagas {

  static* init(ctx) {
    yield put(showLoading())


    const result = yield call(ApiSagas.query, company.getLocations)

    const userSelector = yield select(AuthSelectors.user)
    const seo = yield call(UserLocationsSagas.transformSeo, { isCompany: userSelector.isCompany })

    if (!result.errors) {
      const data = yield call(UserLocationsSagas.transformData, result.data.me.company.locations)

      yield all([
        put(actions.setData(data)),
        put(AppActions.setJsonld(null)),
        put(AppActions.setSeo(seo)),
        put(actions.setLoaded(true)),
        put(hideLoading()),
      ])
    }

    return {}
  }

  static transformData(data) {
    return data.filter((c) => !c.is_main)
      .map(({ id, name, address, address_2, city, zip_code }) => (
        { id, name, address, address_2, city, zip_code }
      ))
  }

  static* updateLocations({ payload }) {
    const values = payload.values

    const user = yield select(AuthSelectors.user)
    const data = yield select(selectors.data)

    const locations = yield call(UserLocationsSagas.getDiff, data || [], values)

    const result = yield call(ApiSagas.query, company.updateLocations, { id: user.company.id, locations })

    if (result.errors && result.errors.length) {
      yield put(actions.updateLocationsError(result.errors))
    } else {
      const d = yield call(UserLocationsSagas.transformData, result.data?.locations?.locations)

      yield put(actions.setData(d))
      yield put(actions.updateLocationsSuccess(d))
    }
  }

  static getDiff(source, values) {
    return getDiffQuery(source.map(({ id, key, main }) => ({ id, key, main })), values)
  }

  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const candidateTitleLabel = i18nStore[i18nLang].translation.SEO_candidate_locations_title
      const candidateDescriptionLabel = i18nStore[i18nLang].translation.SEO_candidate_locations_description
      const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_locations_title
      const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_locations_description
      const isCompany = data.isCompany

      const seoProps = {
        title: isCompany ? companyTitleLabel : candidateTitleLabel,
        image: '',
        description: isCompany ? companyDescriptionLabel : candidateDescriptionLabel,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userLocations)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(actions.updateLocations.getType(), UserLocationsSagas.updateLocations),
    ])
  }

}
