import { colors, global } from '../../theme'


export default {
  ...global,
  wrapper: {
    backgroundColor: colors.pureWhite,
    height: '100%',
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
