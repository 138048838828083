import { useState, useEffect } from 'react'


export default function useLegacyBrowser() {
  const [isLegacyBrowser, setIsLegacyBrowser] = useState(false)

  useEffect(() => {
    if (window?.document?.documentMode) {
      setIsLegacyBrowser(true)
    }
  }, [setIsLegacyBrowser])

  return isLegacyBrowser
}
