/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as authActions, selectors as authSelectors } from '../../redux/auth/redux'
import { actions as UserFavoriteActions } from '../UserFavorite/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'
import UserQueries from '../../graphql/query/User/index'


import { getNewEventById } from './queries'
import { actions } from './redux'


export default class UserEventSagas {

  // eslint-disable-next-line complexity
  static* init({ query }) {
    yield put(showLoading())

    const events = yield call(ApiSagas.query, getNewEventById, { id: query.id })

    if (events.errors || !events.data.getNewEventById) {
      yield put(hideLoading())
      return { error: 404 }
    }

    yield put(actions.setData(events.data.getNewEventById))

    const seo = yield call(UserEventSagas.transformSeo, query.id)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* updateFavoriteUser({ payload }) {
    const { data } = payload
    const { id, isAlreadyFavorite } = data

    const favorites = yield call(ApiSagas.query,
      UserQueries.updateUserFavorite,
      {
        favoriteUser: {
          create: isAlreadyFavorite ? [] : [parseInt(id, 10)],
          delete: isAlreadyFavorite ? [parseInt(id, 10)] : [],
        },
      })

    if (favorites.errors) {
      yield put(actions.updateFavoriteUserError(favorites.errors))
    }

    const user = yield select(authSelectors.user)

    yield put(authActions.setUser({ ...user, favoritesUsers: favorites.data.updateFavoriteUser.favoritesUsers }))
    yield put(UserFavoriteActions.setData(favorites.data.updateFavoriteUser.favoritesUsers))
    yield put(actions.updateFavoriteUserSuccess())

    return {}
  }


  static* transformSeo(id) {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const title = i18nStore[i18nLang].translation.SEO_candidate_account_my_events_title
    const description = i18nStore[i18nLang].translation.SEO_candidate_account_my_events_description

    const seoProps = {
      title,
      image: '',
      description,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userEvent, { id })}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      takeLatest(actions.updateFavoriteUser.getType(), UserEventSagas.updateFavoriteUser),
    ])
  }

}
