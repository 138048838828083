import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import { getSplittedLinks } from '../../helpers/GraphqlHelpers'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { getProfile, updateProfile } from './queries'
import { actions, selectors } from './redux'


export default class UserProfileSagas {

  static* init(ctx) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, getProfile)

    const seo = yield call(UserProfileSagas.transformSeo)

    if (!result.errors) {
      const data = yield call(UserProfileSagas.transformData, result.data.me)

      yield all([
        put(actions.setData(data)),
        put(AppActions.setJsonld(null)),
        put(AppActions.setSeo(seo)),
        put(actions.setLoaded(true)),
        put(hideLoading()),
      ])
    } else {
      console.log('UserProfile: Errors', result.errors)
    }

    return {}
  }

  static transformData(data) {
    return {
      companyFunction: data?.companyFunction?.id,
      image: data.avatar && { id: data.avatar, path: data.avatar },
      phone: data.phone || '',
      phone_professional: data.phone_professional || '',
      domains: data.domains?.map((d) => d?.id) || [],
      company_function_description: data?.company_function_description || '',
      current_needs: data?.current_needs || '',
      ...getSplittedLinks(data.links),
    }
  }

  static* updateProfile({ payload }) {
    const values = payload.values

    const user = yield select(AuthSelectors.user)
    const data = yield select(selectors.data)

    const input = {
      company_function_id: values.companyFunction,
      company_function_description: values.company_function_description,
      avatar: values.image?.id && values.image.id !== data.image?.id ? values.image.id : undefined,
      phone_professional: values.phone_professional,
      phone: values.phone,
      domains: values.domains && { sync: values.domains },
      links: {
        linkedin: values?.linkedin,
        twitter: values?.twitter,
        facebook: values?.facebook,
      },
      current_needs: values.current_needs,
    }

    const result = yield call(ApiSagas.query, updateProfile, { id: user.id, input })

    if (result.errors && result.errors.length) {
      yield put(actions.updateProfileError(result.errors))
    } else {
      const d = yield call(UserProfileSagas.transformData, result.data?.updatePersonalInformation)

      yield put(AuthActions.setUser({ ...user, ...result.data?.updatePersonalInformation }))
      yield put(actions.setData(d))
      yield put(actions.updateProfileSuccess(d))
    }
  }

  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_profile_title
      const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_profile_description

      const seoProps = {
        title: companyTitleLabel,
        image: '',
        description: -companyDescriptionLabel,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userProfile)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(actions.updateProfile.getType(), UserProfileSagas.updateProfile),
    ])
  }

}
