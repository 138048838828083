import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import withMemo from '../../decorators/WithMemo'


function Seo(props) {
  const {
    title, description, type, image,
    twitter_card, url, twitter_account, noindex,
    base,
    canonical, next, prev,
  } = props

  return (
    <Head>
      <title>{title}</title>
      <meta
        property="og:title"
        content={title}
      />
      <meta
        name="description"
        content={description}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:type"
        content={type}
      />
      <meta
        property="og:url"
        content={url}
      />
      <meta
        property="og:locale"
        content="fr"
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta
        name="twitter:card"
        content={twitter_card}
      />
      <base href={base} />
      {image && (
        <>
          <meta
            property="og:image"
            content={image}
          />
          <meta
            name="twitter:image"
            content={image}
          />
        </>
      )}
      {twitter_account && (
        <>
          <meta
            property="twitter:site"
            content={twitter_account}
          />
          <meta
            name="twitter:creator"
            content={twitter_account}
          />
        </>
      )}
      {noindex && (
        <meta
          name="robots"
          content="noindex, nofollow"
        />
      )}
      <link
        id="canonical"
        rel="canonical"
        href={canonical || url}
      />
      {prev && (
        <link
          rel="prev"
          href={prev}
        />
      )}
      {next && (
        <link
          rel="next"
          href={next}
        />
      )}
    </Head>
  )
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  twitter_card: PropTypes.string,
  twitter_account: PropTypes.string,
  noindex: PropTypes.bool,
  base: PropTypes.string,
  canonical: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
}

Seo.defaultProps = {
  description: '',
  title: '',
  url: '',
  image: null,
  type: 'website',
  twitter_card: 'summary_large_image',
  twitter_account: null,
  noindex: false,
  base: '',
  canonical: '',
  prev: '',
  next: '',
}

export default withMemo()(Seo)
