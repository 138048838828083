import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'
import { actions as AppActions } from '../../redux/app/redux'
import ApiSagas from '../../redux/api/sagas'
import PaymentQueries from '../../graphql/query/Payment'

import { actions } from './redux'


export default class UserPaymentSagas {

  static* init() {
    yield put(showLoading())

    const seo = yield call(UserPaymentSagas.transformSeo)

    yield all([
      yield put(AppActions.setSeo(seo)),
      yield put(actions.setLoaded(true)),
      yield put(hideLoading()),
    ])

    return {}
  }


  static* updatePaymentMethod({ payload }) {
    // eslint-disable-next-line max-len
    const result = yield call(ApiSagas.query, PaymentQueries.CreateSepaMandate, { input: { iban: payload.values.iban } })

    if (result.errors) {
      yield put(actions.updatePaymentMethodError(result.errors))
    } else {
      window.location.href = result.data.createSepaMandate.merchantRedirectUrl
    }
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const titleLabel = i18nStore[i18nLang].translation.SEO_user_payment_title
    const descriptionLabel = i18nStore[i18nLang].translation.SEO_user_payment_description

    const seoProps = {
      title: titleLabel,
      image: '',
      description: descriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPayment)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      takeLatest(actions.updatePaymentMethod.getType(), UserPaymentSagas.updatePaymentMethod),
    ])
  }

}
