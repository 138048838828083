/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import { filterDateTypeReverseSlugs } from '../../../server/app/Utils/SitemapHelper'
import { newEventsSortConfig, newEventsFiltersConfig } from '../../components/NewEventsResults/config'
import app from '../../config/app'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { getNewEventsByFilters } from './queries'
import { actions } from './redux'


const CREATED_AT = 'created_at'
const PLACE = 'place'

const DESC = 'DESC'
const ASC = 'ASC'

export default class UserEventsSagas {

  // eslint-disable-next-line complexity
  static* init({ query }) {
    yield put(showLoading())

    const params = {
      first: 9,
      page: 1,
      filters: {
        date: '',
      },
      orderBy: [
        {
          field: CREATED_AT,
          order: DESC,
        },
      ],
    }

    if (query.date) {
      params.filters.date = query.date !== 'all' ? filterDateTypeReverseSlugs[query.date] : ''
    }

    if (query.ordre) {
      params.orderBy[0].field = query.ordre === 'par-date' ? CREATED_AT : PLACE
      params.orderBy[0].order = query.ordre === 'par-date' ? DESC : ASC
    }

    if (query.page) {
      params.page = parseInt(query.page, 10)
    }

    const events = yield call(ApiSagas.query, getNewEventsByFilters, { first: params.first, filters: params.filters, orderBy: params.orderBy, page: params.page })

    if (events.errors || !events.data.newEventsByFilters) {
      yield put(hideLoading())
      return { error: 404 }
    }

    const transformEvents = UserEventsSagas.transformResults(events.data.newEventsByFilters)

    yield put(actions.setData(transformEvents))

    const seo = yield call(UserEventsSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const title = i18nStore[i18nLang].translation.SEO_candidate_account_my_events_title
    const description = i18nStore[i18nLang].translation.SEO_candidate_account_my_events_description

    const seoProps = {
      title,
      image: '',
      description,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userEvents)}`,
      noindex: true,
    }

    return seoProps
  }

  static transformResults(events) {
    const filterConfig = newEventsFiltersConfig
    const sortConfig = newEventsSortConfig

    return {
      filters: undefined,
      sortConfig,
      filterConfig,
      events: events.data.map((event) => ({
        ...event,
        route: routes.userEvent.name,
        routeParams: { id: event.id },
      })),
      pagination: {
        currentPage: events.paginatorInfo.currentPage,
        totalPage: events.paginatorInfo.lastPage,
        total: events.paginatorInfo.total,
        route: routes.userEvents.name,
        routeBase: routes.userEvents.name,
      },
    }
  }

  static* loop() {
    yield all([
    ])
  }

}
