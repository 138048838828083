/* eslint-disable max-lines, camelcase, no-console */

import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import FileEntry from '../../data/models/FileEntry'
import { getDiffQuery, getLinksDiffQuery, getSplittedLinks } from '../../helpers/GraphqlHelpers'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { getCompany, updateCompany } from './queries'
import { actions, selectors } from './redux'


const getPictures = (source, values) => {
  const sourceByKey = (source && source.gallery
      && source.gallery.reduce(
        (r, { id, ...value }) => ({
          ...r,
          [id]: { id, ...value },
        }),
        {}
      ))
    || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceByKey[value.id]

    if (value.id && src) {
      if (value.main !== src.main) {
        update.push({ id: src.id, is_main: value.main })
      }
    } else if (value.key && !src) {
      create.push({ image: value.key, is_main: value.main })
    }
  }
  const valuesIds = values.gallery.map((entry) => entry.id)
  const _delete = !source ? [] : source.gallery
    .filter((entry) => !valuesIds.includes(entry.id))
    .map((entry) => entry.id)

  values.gallery.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}

const getFiles = (source, values) => {
  const sourceByKey = (source && source.files
      && source.files.reduce(
        (r, { id, ...value }) => ({
          ...r,
          [id]: { id, ...value },
        }),
        {}
      ))
    || {}
  const update = []
  const create = []

  const compare = (value) => {
    const src = sourceByKey[value.id]

    if (value.id && src) {
      if (value.title !== src.title) {
        update.push({ id: src.id, title: value.title })
      }
    } else if (value.key && !src) {
      create.push({ path: value.key, title: value.title })
    }
  }
  const valuesIds = values.files.map((entry) => entry.id)
  const _delete = !source ? [] : source.files.filter((entry) => !valuesIds.includes(entry.id)).map((entry) => entry.id)

  values.files.forEach(compare)

  return {
    create: create || null,
    update: update || null,
    delete: _delete || null,
  }
}

export default class UserCompanySagas {

  static* init(ctx) {
    yield put(showLoading())
    const isOwner = yield select(AuthSelectors.isOwner)

    if (!isOwner) {
      return { error: 404 }
    }

    const result = yield call(ApiSagas.query, getCompany)

    const seo = yield call(UserCompanySagas.transformSeo)

    if (!result.errors) {
      const data = yield call(UserCompanySagas.transformCompanyData, result.data.me.company)

      yield all([
        put(actions.setData(data)),
        put(AppActions.setJsonld(null)),
        put(AppActions.setSeo(seo)),
        put(actions.setLoaded(true)),
        put(hideLoading()),
      ])
    } else {
      console.log('UserCompany: Errors', result.errors)
    }

    return {}
  }

  static transformCompanyData(values) {
    return {
      id: values.id,
      name: values.name || '',
      location_name: values.location_name || '',
      siret: values.siret || '',
      address: values.location?.address || '',
      address_2: values.location?.address_2 || '',
      zip_code: values.location?.zip_code || '',
      city: values.location?.city || '',
      creation_year: (values.creation_year && String(values.creation_year)) || '',
      workforce: values?.workforce || '',
      turnover: values.turnover || '',
      isActivePremium: values.isActivePremium || '',
      sector: values?.domains?.[0]?.sector?.id || null,
      domains: values?.domains?.map((d) => d.id) || [],
      phone: values?.phone,
      email: values?.email,
      logo: values.logo && { id: values.logo, path: values.logo },
      description: values.description || '',
      video: values.video || '',
      gallery: values.pictures.map(({ id, image, is_main }) => ({
        id,
        key: image,
        path: image,
        main: is_main,
      })),
      files: values.files.map(FileEntry),
      links: values.links,
      certifications: values?.certifications?.map((c) => ({ ...c, domain: c.domain?.id })) || [],
      custom_certifications: values?.custom_certifications,
      contacts:
        values?.contacts?.map((c) => ({
          id: c.id,
          first_name: c.first_name,
          last_name: c.last_name,
          email: c.email,
          phone: c.phone,
          company_function_id: c.companyFunction?.id,
          custom_company_function: c.customCompanyFunction,
        })) || null,
      ...getSplittedLinks(values.links),
      slug: values.full_slug || '',
      plain_certifications: values?.plain_certifications ?? [],
      references: values?.references ?? [],
    }
  }

  static* updateCompany({ payload }) {
    const values = payload.values
    const socialNetwork = ['facebook', 'twitter', 'linkedin', 'instagram', 'website']

    socialNetwork.forEach((key) => {
      if (values[key] && values[key].length > 0 && values[key] === 'https://') {
        values[key] = ''
      }
    })

    const data = yield select(selectors.data)
    const user = yield select(AuthSelectors.user)

    const links = yield call(getLinksDiffQuery, data.links, values)

    const input = {
      name: values.name || '',
      siret: values.siret || '',
      address: values.address || '',
      address_2: values.address_2 || '',
      zip_code: values.zip_code || '',
      city: values.city || '',
      creation_year: values.creation_year || null,
      workforce: values?.workforce || '',
      turnover: values.turnover || '',
      domains: values.domains
        ? {
          sync: values.domains,
        }
        : undefined,
      logo: values.logo?.id && values.logo.id !== data.logo?.id ? values.logo.id : undefined,
      description: values.description || '',
      video: values.video || '',
      phone: values.phone || '',
      email: values.email || '',
      pictures: getPictures(data, values),
      files: getFiles(data, values),
      contacts: getDiffQuery(data?.contacts, values?.contacts),
      certifications: values.certifications
        ? {
          sync: values.certifications?.map((c) => c.id),
        }
        : undefined,
      custom_certifications: values?.custom_certifications,
      plain_certifications: values.plain_certifications,
      references: values.references,
      links,
    }

    const result = yield call(ApiSagas.query, updateCompany, { id: data.id, input })

    if (result.errors && result.errors.length) {
      yield put(actions.updateCompanyError(result.errors))
    } else {
      const transformedData = yield call(
        UserCompanySagas.transformCompanyData,
        result.data.updateCompanyInformation
      )

      yield put(actions.setData({ ...data, ...transformedData }))
      yield put(actions.updateCompanySuccess(result.data.updateCompany))
      // update user's company infos
      yield put(
        AuthActions.setUser({
          ...user,
          company: {
            ...user.company,
            ...data,
            ...result.data.updateCompanyInformation,
          },
        })
      )
    }
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang]?.translation.SEO_company_infos_title
    const companyDescriptionLabel = i18nStore[i18nLang]?.translation.SEO_company_infos_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userCompany)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([takeLatest(actions.updateCompany.getType(), UserCompanySagas.updateCompany)])
  }

}
