import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


export const getNewEventsByFilters = (variables, headers) => query(gql`
    query newEventsByFilters($orderBy: [OrderByClause!], $first: Int!, $filters: NewEventFilterClause, $page: Int) {
        newEventsByFilters(first: $first, orderBy: $orderBy, filters: $filters, page: $page) {
            paginatorInfo {
              lastPage
              total
              currentPage
            }
            data {
              id
              label
              date
              place
            }
        }
    }
  `,
variables, headers)


