/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { GDPRSettings, GDPRConfigBuilder, cookiesTool } from 'react-gdpr'

import { actions as AppActions, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as TrackingActions } from '../../redux/tracking/redux'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function GDPRSettingsPanel(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cookiesSettings = useSelector(AppSelectors.cookiesSettings)

  const [allowRender, setAllowRender] = useState(false)
  const [renderCounter, setRenderCounter] = useState(0)

  function incRender() {
    setRenderCounter(renderCounter + 1)
  }

  function handleToggle(id, value) {
    cookiesTool.setActive(id, value)
    if (id === 'analytics' && value) {
      dispatch(TrackingActions.enableTracking())
    } else if (id === 'ads') {
      dispatch(TrackingActions.ad(value))
    } else {
      dispatch(TrackingActions.disabledTracking())
    }
    incRender()
  }

  function handleValidate() {
    cookiesTool.setActive('cookiesEdited', true)
    dispatch(AppActions.closeCookiesSettings())
  }

  useEffect(() => {
    setAllowRender(true)
  }, [])

  if (!allowRender || !cookiesSettings) {
    return null
  }

  const configBuilder = new GDPRConfigBuilder()

  configBuilder.setLocale(t('GDPR_gdpr_settings_title'),
    t('GDPR_gdpr_settings_decription'),
    t('GDPR_gdpr_settings_button_accept_label'),
    t('GDPR_gdpr_settings_toggle_active_label'),
    t('GDPR_gdpr_settings_toggle_inactive_label'),
    t('GDPR_gdpr_settings_always_active'))
    .addSetting('analytics',
      t('GDPR_gdpr_settings_analytics_title'),
      t('GDPR_gdpr_settings_analytics_description'),
      cookiesTool.isActive('analytics'),
      true)
    .addSetting('ads',
      t('GDPR_gdpr_settings_ads_title'),
      t('GDPR_gdpr_settings_ads_description'),
      cookiesTool.isActive('ads'),
      true)

  const config = configBuilder.getConfig()

  return (
    <div className={classes.container}>
      <GDPRSettings
        onValidate={handleValidate}
        onToggle={handleToggle}
        {...config}
      />
    </div>
  )
}

GDPRSettingsPanel.styles = styles

export default withMemo()(GDPRSettingsPanel)
