import { colors, desktop, sizes } from '../../theme'


export default {
  container: {
    position: 'fixed',
    left: 'auto',
    right: 'auto',
    width: '100%',
    maxWidth: `${sizes.maxDesktop / 10}rem`,
    backgroundColor: 'red',
    top: 0,
    zIndex: 9999,
  },
  wrapper: {
    position: 'absolute',
    left: '20rem',
    top: '3.8rem',
    backgroundColor: colors.darkOrange,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '.4rem',
    cursor: 'pointer',
    userSelect: 'none',
    padding: '.6rem',
    '&:hover:not(.disabled)': {
      backgroundColor: colors.burntOrange,
    },
    ...desktop({
      left: '1.5rem',
      top: '4.6rem',
    }),
  },
  link: {
    display: 'inline-flex',
    color: colors.nightRider,
    alignItems: 'center',
  },
  icon: {
    width: '1.8rem',
    height: '1.8rem',
  },
}
