import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'
import { OfferCardFragment } from '../../graphql/query/Fragments/OfferCardFragment'


export const getMyCandidacies = (variables, headers) => query(gql`
    query getMyCandidacies {
        me {
            candidacies(first:500) {
                data {
                    id
                    applied_at
                    status
                    offer {
                        ...offerCard
                        user {
                            first_name
                            last_name
                            slug
                            avatar
                        }
                    }
                }
            }
        }
    }
    ${OfferCardFragment.fragment}
  `,
variables, headers)
