import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'
import { PaginationFragment } from '../../graphql/query/Fragments/PaginationFragment'


export const getResources = (variables, headers) => query(gql`
    query means($first: Int!, $page: Int, $orderBy: [OrderByClause!]) {
        means(first: $first, page: $page, orderBy: $orderBy) {
          data {
            title
            slug
            category_id
            category {
              name
            }
            description
            file
            video
            image
          }
          paginatorInfo {
            ...pagination
          }
        }
    }
    ${PaginationFragment.fragment}
  `,
variables, headers)
