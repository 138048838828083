import { routeParamSlug } from '../../helpers/UrlHelpers'
import routes from '../../routes'
import Sector from '../../data/models/Sector'
import Certification from '../../data/models/Certification'
import CompanyFunction from '../../data/models/CompanyFunction'
import Category from '../../data/models/Category'

import { pageType } from './redux'


export default class AppTransformers {

  static initialData(data) {
    const sectors = AppTransformers.transformSectors(data)
    const certifications = AppTransformers.transformCertifications(data)
    const companyFunctions = AppTransformers.transformCompanyFunctions(data)
    const categories = AppTransformers.transformCategories(data)
    const menus = AppTransformers.transformMenus(data)
    const pages = AppTransformers.transformPages(data)

    return { sectors, pages, menus, certifications, companyFunctions, categories }
  }

  static transformSectors(data) {
    return data?.sectors?.map(Sector)
  }

  static transformCertifications(data) {
    return data?.certifications?.map(Certification)
  }

  static transformCompanyFunctions(data) {
    return data?.companyFunctions?.map(CompanyFunction)
  }
  static transformCategories(data) {
    return data?.categories?.map(Category)
  }

  static transformMenus(data) {
    const getMenuable = (entry) => {
      if (!entry.menuable) {
        return entry.link
      }
      if (entry.menuable.__typename === 'Page') {
        return entry.menuable.full_slug
      }
      if (entry.menuable.__typename === 'Route') {
        return `/${entry.menuable.slug}`
      }
      return null
    }

    const getRoute = (entry) => {
      if (entry.menuable) {
        if (entry.menuable.__typename === 'Page') {
          return {
            route: routes.page.name,
            routeParams: {
              slug: routeParamSlug(entry.menuable.full_slug),
            },
          }
        }
        if (entry.menuable.__typename === 'Route') {
          const route = routes[entry.menuable.key]

          if (route) {
            return {
              route: route.name,
            }
          }
        }
      }
      return {}
    }

    const transform = (entry) => ({
      ...entry,
      link: getMenuable(entry),
      ...getRoute(entry),
      children: entry.children && entry.children.map(transform),
    })

    const returnedData = data.menus.map(transform)

    return returnedData
      .map((entry) => ({
        ...entry,
        // get children inside their parent
        entries: entry.children.length > 0
          ? entry.children.map((child) => returnedData.find((e) => e.id === child.id))
          : null,
      }))
      // only keep parents
      .filter(({ parent }) => parent === null)
      // group by location
      .reduce((r, entry) => ({ ...r, [entry.location]: [...r[entry.location] || [], entry] }), {})
  }

  static transformPages(data) {
    return Object.values(pageType)
      .reduce((r, key) => ({
        ...r,
        [key]: {
          link: (data[key] && data[key].full_slug.replace(/^\//, '')) || '/',
          title: (data[key]?.title) || '',
        },
      }), {})
  }

  static transformArticleCategories(data) {
    return data.articleCategories
  }

}
