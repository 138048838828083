import { createAction, createReducer } from 'redux-act'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'offer'


//
// Initial state
//
const initialState = {
  applyToOffer: requestInitialState(),
  updateSavedOffers: requestInitialState(),
}

//
// Actions
//
export const actions = {
  // savedOffers
  updateSavedOffers: createAction('Offer: updateSavedOffers', (values) => ({ values })),
  updateSavedOffersSuccess: createAction('Offer: updateSavedOffersSuccess', (data) => data),
  updateSavedOffersError: createAction('Offer: updateSavedOffersError', (errors) => Errors(errors)),
  // applyToOffer
  applyToOffer: createAction('Offer: applyToOffer', (values) => ({ values })),
  applyToOfferSuccess: createAction('Offer: applyToOfferSuccess', (data) => data),
  applyToOfferError: createAction('Offer: applyToOfferError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  ...requestReducers(actions, 'applyToOffer'),
  ...requestReducers(actions, 'updateSavedOffers'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const applyToOffer = (state) => root(state).applyToOffer

export const selectors = {
  applyToOffer,
}
