import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const notificationPreferencesFragment = gql`
    fragment notificationPreferencesFragment on User{
        newsletter
        offer_notification_email
        notification_scheduling
    }
`

export const getNotificationPreferences = (variables, headers) => query(gql`
    query GetProfile {
      me {
          ...notificationPreferencesFragment
      }
    }
        ${notificationPreferencesFragment}
  `,
variables, headers)


export const updateNotificationPreferences = (variables, headers) => query(gql`
    mutation updateNotificationPreferences(
        $id: ID!,
        $input: UpdateNotificationPreferencesInput!
    ) {
        updateNotificationPreferences(id: $id, input: $input) {
            ...notificationPreferencesFragment
        }
    }
        ${notificationPreferencesFragment}
`, variables, headers)


const notificationsPreferencesFragment = gql`
    fragment notificationsPreferencesFragment on User{
        notificationPreferences {
            id
            type
            subtypes
            is_active
        }
    }
`

export const getNotificationsPreferences = (variables, headers) => query(gql`
    query GetProfile {
      me {
          ...notificationsPreferencesFragment
      }
    }
        ${notificationsPreferencesFragment}
  `,
variables, headers)


export const updateNotificationsPreferences = (variables, headers) => query(gql`
    mutation updateNotificationsPreferences(
        $id: ID!,
        $notificationPreferences: UpdateNotificationPreferencesRelation!
    ) {
        updateNotificationsPreferences(id: $id, notificationPreferences: $notificationPreferences) {
            ...notificationsPreferencesFragment
        }
    }
        ${notificationsPreferencesFragment}
`, variables, headers)
